<template>
  <b-container fluid class="my-5">
    <b-row class="justify-content-center mx-auto" cols="12" sm="8" md="6">
      <b-col class="custom-card mx-auto" cols="12" sm="8" md="6">
        <b-button v-if="isGuestIdentified" variant="light" class="back-button" @click="resetToCodeSearch">↩</b-button>
        
        <div class="alerts-container">
        <b-alert        
          :show="showSuccessAlert"
          variant="success"
          dismissible
          @dismissed="showSuccessAlert=false"
        >
          ¡Genial, te hemos encontrado!
        </b-alert>
        <b-alert
          :show="showWarningAlert"
          variant="info"
          dismissible
          @dismissed="showWarningAlert=false"
        >{{ warningMessage }}</b-alert>
        </div>

        <!-- Visualización condicional basada en la identificación del invitado -->
        <div v-if="!isGuestIdentified && !isOutOfDate">
          <h2 class="card-title mb-4 text-center">¡Hola! Para acceder, introduce tu código de invitación.</h2>
          <b-form @submit.prevent="identifyGuest">
            <b-form-group label="Código de invitación:" label-for="guestIdentifier">
              <b-form-input v-model="guestIdentifier" id="guestIdentifier" placeholder="Ejemplo: ABC123" required></b-form-input>
            </b-form-group>
            <b-button class="card-button" type="submit" variant="primary" block>Buscar</b-button>
          </b-form>
        </div>

        <!-- Message for out of date -->
        <div v-if="isOutOfDate">
          <h2 class="card-title mb-4 text-center">¡Ups! Llegas un poco tarde.</h2>
          <p class="text-center">Lamentablemente, el plazo para confirmar asistencia por la web ha terminado. Por favor, ponte en contacto con nosotros 😊</p>
          <div class="text-center mt-4">
            <b-button class="card-button" variant="primary" @click="contactUs">Escríbenos</b-button>
          </div>
        </div>
        
        <div v-if="isGuestIdentified && guestNames.length > 0">
          <h3>Selecciona tu nombre:</h3>
          <b-form-group>
            <b-form-radio-group v-model="selectedGuestId" @change="selectGuest">
              <b-form-radio
                v-for="guest in guestNames"
                :key="guest.id"
                :value="guest.id"
                :disabled="guest.confirmado"
                v-bind:class="{ 'is-confirmed': guest.confirmado }">
                {{ guest.nombre }}
                <span v-if="guest.confirmado">(confirmado)</span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <!-- <div v-if="allGuestsConfirmed" class="thank-you-section">
          <h2 class="thank-you-title">¡Hurra! Todos habéis confirmado 🎉</h2>
          <p class="thank-you-message">Estamos super emocionados de veros a todos. ¡Gracias por ser parte de este día!</p>
          <div class="images-container">
            <img :src="thankYouGif" alt="Gracias de corazón" class="thank-you-gif">
            <img :src="thankYouGif2" alt="Gracias de corazón" class="thank-you-gif">
          </div>
        </div> -->
        <div v-if="allGuestsConfirmed" class="thank-you-section">
          <h2 class="thank-you-title">¡Genial! {{ confirmedGuestCount === 1 ? $t('confirmacion') : $t('confirmacion2') }} 🎉</h2>
          <p class="thank-you-message">Estamos súper emocionados de ver{{ confirmedGuestCount === 1 ? 'te' : $t('veros') }}. ¡Gracias por ser parte de este día!</p>
          <!-- Nuevo mensaje de invitación a la página de FAQs -->
          <div class="images-container">
            <b-img :src="thankYouGif" alt="Gracias de todo corazón" class="thank-you-gif"></b-img>
            <b-img :src="thankYouGif2" alt="Gracias de todo corazón" class="thank-you-gif"></b-img>
          </div>
          <div class="mt-4">
            <b-card-text>Recuerda visitar la
            <router-link to="/faqs" class="faq-link">página de Preguntas Frecuentes</router-link>
            para que tengas más información sobre el día de la boda.
          </b-card-text>
          </div>
        </div>

        <!-- <div v-if="allGuestsConfirmed" class="thank-you-section">
          <h2 class="thank-you-title">¡Yupaychani! Todos han confirmado 🎉</h2>
          <p class="thank-you-message">Estamos súper emocionados de verlos a todos. ¡Gracias por ser parte de este día!</p>
          <div class="images-container">
            <img :src="thankYouGif" alt="Agradecimiento de corazón" class="thank-you-gif">
            <img :src="thankYouGif2" alt="Agradecimiento de corazón" class="thank-you-gif">
          </div>
        </div> -->

        <!-- RSVP form displayed after guest identification -->
        <div v-if="isGuestSelected && formVisible">
          <p class="card-title mb-3 mt-2">Por favor, confirma tu asistencia a continuación:</p>
          <b-form @submit.prevent="submitRsvp">
          <!-- Nombre completo -->
          <b-form-group label="Nombre completo:" label-for="nombre">
            <b-form-input v-model="formData.nombre" id="nombre" placeholder="Nombre y apellidos" @change="validateField('nombre')"></b-form-input>
            <b-form-invalid-feedback :state="!formErrors.nombre" v-if="formErrors.nombre">
              Este campo es obligatorio.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Alergia o intolerancia -->
          <b-form-group label="¿Tienes alguna alergia o intolerancia alimentaria? (especifica si es alergia):" label-for="alergia">
            <b-form-input v-model="formData.alergia" id="alergia" placeholder="Indica aquí tus alergias o intolerancias"></b-form-input>
          </b-form-group>

          <!-- Pregunta sobre quedarse a dormir -->
          <b-form-group label="¿Te quedarás a dormir?" label-for="dormir" v-if="guestData && preguntasEspeciales">
            <b-form-select v-model="formData.dormir" id="dormir" @change="validateField('dormir')">
              <option disabled value="">Por favor, selecciona</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
            </b-form-select>
            <b-form-invalid-feedback :state="!formErrors.dormir" v-if="formErrors.dormir">
              Este campo es obligatorio.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Información sobre el alojamiento y video del tipi -->
          <div v-if="formData.dormir === 'SI' && notaCostoDormir" class="my-3">
            <b-card-text class="text-info">
              ¡Yay! Nos encanta que te quedes a pasar la noche 🌜 Para cubrir parte de los costes, pedimos una contribución de 35€ por persona. Si esto te supone algún inconveniente, no te preocupes, charlamos y lo solucionamos. ¡Queremos que disfrutes al máximo sin estrés!
            </b-card-text>
            <b-card-text class="text-info">
              Según la disponibilidad, ofreceremos la opción de alojamiento en <strong>apartamento rural</strong> o alojamiento en un <strong>tipi singular "glamping"</strong>🌲⛺✨ Próximamente, nos pondremos en contacto para coordinar los detalles específicos.
            </b-card-text>
            <b-button variant="info" @click="showTipiModal = true">¿Curioso/a sobre el tipi? ¡Mira este video!</b-button>
            
            <!-- Modal para el video -->
            <b-modal v-model="showTipiModal" title="Descubre el Tipi" hide-footer size="lg">
              <div class="d-block text-center">
                <video style="max-height: 70vh; width: 100%; object-fit: cover;" controls autoplay muted>
                  <source src="@/assets/Tipis.mp4" type="video/mp4">
                  Tu navegador no soporta videos HTML5, pero puedes descargar el video para verlo offline.
                </video>
              </div>
            </b-modal>
          </div>

          <!-- Pregunta sobre el desayuno, mostrada solo si se quedará a dormir (formData.dormir === 'SI') -->
          <b-form-group label="¿Te quedarás al desayuno del día siguiente?" label-for="desayuno" v-if="formData.dormir === 'SI'">
            <b-form-select v-model="formData.desayuno" id="desayuno" @change="validateField('desayuno')">
              <option disabled value="">Por favor, selecciona</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
            </b-form-select>
            <b-form-invalid-feedback :state="!formErrors.desayuno" v-if="formErrors.desayuno">
              Este campo es obligatorio.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Información sobre el costo del servicio de autobús -->
          <!-- <b-alert variant="info" show>
            Queremos informarte que el servicio de autobús para el traslado ida y vuelta tendrá un costo de 33€ por persona. Esta opción está pensada para tu comodidad y seguridad, asegurando que puedas disfrutar del evento sin preocupaciones. ¡Esperamos que esta opción te resulte conveniente!
          </b-alert> -->
          <!-- Servicio de autobús con nota sobre el costo -->
          <!-- <div variant="info" show class="mb-3">
            <p class="text-muted">Nota: El servicio de autobús tiene un costo de 33€ por persona (ida y vuelta).</p>
          </div> -->

          <!-- Pregunta sobre el servicio de autobús para llegar -->
          <b-form-group label="¿Utilizarás el servicio de autobús para llegar el sábado a la boda?" label-for="busLlegada">
            <b-form-select v-model="formData.busLlegada" id="busLlegada" @change="validateField('busLlegada')">
              <option disabled value="">Por favor, selecciona</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
            </b-form-select>
            <b-form-invalid-feedback :state="!formErrors.busLlegada" v-if="formErrors.busLlegada">
              Este campo es obligatorio.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Pregunta sobre el servicio de autobús para volver -->
          <b-form-group label="¿Utilizarás el servicio de autobús para volver el sábado después de la boda?" label-for="busVuelta">
            <b-form-select v-model="formData.busVuelta" id="busVuelta" :disabled="formData.dormir === 'SI'" @change="validateField('busVuelta')">
              <option disabled value="">{{ formData.dormir === 'SI' ? 'No aplica al quedarse a dormir' : 'Por favor, selecciona' }}</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>
            </b-form-select>
            <b-form-invalid-feedback :state="!formErrors.busVuelta" v-if="formErrors.busVuelta">
              Este campo es obligatorio.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Comentarios adicionales -->
          <b-form-group label="¿Algún comentario adicional?" label-for="comentari">
            <b-form-textarea v-model="formData.comentari" id="comentari" placeholder="Comentarios" rows="3"></b-form-textarea>
          </b-form-group>
            <!-- Submission button with loading indicator -->
            <b-button class="card-button" :disabled="isLoading || formSubmitted" variant="primary" block type="submit">
              <b-spinner small v-if="isLoading"></b-spinner>
              <span v-if="!isLoading">Enviar Confirmación</span>
            </b-button>
          </b-form>
        </div>     
        <b-alert :show="!!confirmationMessage" variant="success" dismissible class="mt-3">{{ confirmationMessage }}</b-alert>
        <b-alert :show="!!errorMessage" variant="danger" class="mt-3">{{ errorMessage }}</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>
 
<script>
  import { database } from '@/config/firebaseConfig.js';
  import { ref as databaseRef, push, get, set, child, query, orderByChild, equalTo, update } from 'firebase/database';
  import ThankYouGif from '@/assets/ThankYouCute3.gif';
  import ThankYouGif2 from '@/assets/ThankYouCute4.gif';

export default {
  data() {
    return {
      // Data properties
      guestIdentifier: '', // Input field for guest invitation code
      isGuestIdentified: false, // Flag to track if guest is identified
      guestData: null, // Data of identified guest
      showSuccessAlert: false, // Flag to show success alert
      showWarningAlert: false, // Flag to show warning alert
      allGuestsConfirmed: false, // Flag to track if all guests have confirmed
      thankYouGif: ThankYouGif, // GIF image for thank you message
      confirmedGuestCount: 0, // Count of confirmed guests
      thankYouGif2: ThankYouGif2, // Second GIF image for thank you message
      notaCostoDormir: false,
      showTipiModal: false, // Controla la visibilidad del modal del tipi
      formData: { // Form data for RSVP
        nombre: '',
        alergia: '',
        busLlegada: '',
        busVuelta: '',
        dormir: '',
        desayuno: '',
        comentari: '',
      },
      formErrors: { // Error flags for form fields
        nombre: false,
        dormir: false,
        desayuno: false,
        busLlegada: false,
        busVuelta: false
      },
      isLoading: false, // Flag to track loading state
      formSubmitted: false, // Flag to track form submission state
      confirmationMessage: '', // Message for successful confirmation
      warningMessage: '', // Warning message for alerts
      errorMessage: '', // Error message for alerts
      guestNames: [], // List of guest names related to the code
      selectedGuestName: '', // Selected guest name
      isGuestSelected: false, // Flag to track if guest is selected
      formVisible: true, // Flag to control visibility of RSVP form
      selectedGuestId: null, // Selected guest ID
      isOutOfDate: false, // Boolean for out-of-date check
    };
  },
  methods: {

    contactUs() {
      const email = 'ksilva.jruano@gmail.com';
      const subject = encodeURIComponent('Consulta sobre asistencia a la boda');
      const body = encodeURIComponent('Hola,\n\nMe he dado cuenta de que he pasado el plazo para confirmar mi asistencia a la boda. ¿Hay algo que pueda hacer? Me encantaría asistir.\n\nGracias,\n[Tu Nombre]');
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    },
    // Method to identify guest based on invitation code
    async identifyGuest() {
      // Reset error and loading states
      this.errorMessage = "";
      this.isLoading = true;
      this.showWarningAlert = false;
      this.showSuccessAlert = false;
      const dbRef = databaseRef(database);

      // Retrieve and format guest code
      const guestCode = this.guestIdentifier.toUpperCase().trim();
      const codeRef = child(dbRef, `guests/${guestCode}`);

      try {
        // Fetch guest data from database
        const snapshot = await get(codeRef);
        if (snapshot.exists()) {
          const data = snapshot.val();

          // Check if 'isOutOfDate' exists and handle default value
          this.isOutOfDate = data.isOutOfDate ?? false;
          if (this.isOutOfDate) {
            return;
          }

          this.guestData = data.invitados;
          // Handle special questions
          this.preguntasEspeciales = data.preguntasEspeciales;
          this.guestNames = Object.keys(data.invitados).map(key => ({
            id: key,
            nombre: data.invitados[key].nombre,
            confirmado: data.invitados[key].confirmado || false
          }))
          this.notaCostoDormir = data.notaCostoDormir || false;
          // Check if all guests have confirmed
          this.checkAllGuestsConfirmed();
          this.isGuestIdentified = true;

          // Show appropriate alert message
          if (this.allGuestsConfirmed) {
            this.showWarningAlert = false;
          } else {
            this.showWarningAlert = true;
          }
          this.warningMessage = "";
          if (this.guestData.length == 1) {
            this.warningMessage = "¡Genial, te hemos encontrado!";
          } else {
            this.warningMessage = `¡Genial, ${this.$t('os6')} hemos encontrado!`;
          }

          // Check if guest(s) already confirmed
          const rsvpsRef = databaseRef(database, 'rsvps');
          const rsvpQuery = query(rsvpsRef, orderByChild('codigoInvitacion'), equalTo(guestCode));
          const rsvpSnapshot = await get(rsvpQuery);

          if (rsvpSnapshot.exists() && rsvpSnapshot.size > 0) {
            let responses = [];
            rsvpSnapshot.forEach(childSnapshot => {
              responses.push(childSnapshot.val().nombre);
            });
            let nombresRespuestas = responses.join(', ');
            if (rsvpSnapshot.size == 1) {
              this.warningMessage = `Has confirmado: ${nombresRespuestas}.`;
            } else {
              this.warningMessage = `Habéis confirmado: ${nombresRespuestas}.`;
            }
          }
        } else {
          throw new Error("Código de invitación no encontrado. Verifica e intenta nuevamente.");
        }
      } catch (error) {
        console.error("Error fetching guest data:", error);
        this.errorMessage = error.message || "Estamos teniendo problemas técnicos. Por favor, intenta de nuevo más tarde.";
      } finally {
        this.isLoading = false;
      }
    },
    // Method to reset the state and UI for guest identification
    resetToCodeSearch() {
      this.isGuestIdentified = false; // Reset flag for guest identification
      this.guestIdentifier = ''; // Reset guest identifier input field
      this.guestNames = []; // Reset list of guest names
      this.selectedGuestId = null; // Reset selected guest ID
      this.isGuestSelected = false; // Reset flag for guest selection
      this.showSuccessAlert = false; // Reset flag for success alert
      this.confirmationMessage = ''; // Reset confirmation message
      this.showWarningAlert = false; // Reset flag for warning alert
      this.isLoading = false; // Reset loading indicator flag
      this.formSubmitted = false; // Reset flag for form submission
      this.allGuestsConfirmed = false; // Reset flag for all guests confirmed
      this.guestData = null; // Reset guest data
      this.isOutOfDate = false;
    },

    // Method to check if all guests have confirmed
    checkAllGuestsConfirmed() {
      // Check if all guests in the list have confirmed their attendance
      const allConfirmed = this.guestNames.every(guest => guest.confirmado);
      this.allGuestsConfirmed = allConfirmed; // Update flag for all guests confirmed
      // Count the number of guests who have confirmed
      this.confirmedGuestCount = this.guestNames.filter(guest => guest.confirmado).length;
    },

    // Method to handle guest selection
    selectGuest() {
      // Reset form and update selected guest data
      this.resetForm(); // Reset form fields
      this.confirmationMessage = ''; // Reset confirmation message
      this.isGuestSelected = true; // Set flag to indicate guest selection
      // Populate form with selected guest's data and show the form
      this.formData.nombre = this.guestData[this.selectedGuestId].nombre;
      this.formVisible = true; // Set form visibility flag to true
    },

    // Method to validate form fields
    validateField(field) {
      // Check if special questions are applicable and skip validation for 'dormir' and 'desayuno'
      if (['dormir', 'desayuno'].includes(field) && !this.preguntasEspeciales) {
        this.formErrors[field] = false; // Reset error flag for the field
        return true; // Skip validation
      }

      // Excluye la validación de busVuelta si dormir es 'SI'
      if (field === 'busVuelta' && this.formData.dormir === 'SI') {
        return true; // Considera que el campo es válido automáticamente
      }
      // Check if the field is empty
      if (!this.formData[field] || String(this.formData[field]).trim() === '') {
        this.formErrors[field] = true; // Set error flag for the field
        return false; // Return validation result
      } else {
        this.formErrors[field] = false; // Reset error flag for the field
        return true; // Return validation result
      }
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    // Method to submit RSVP form
    async submitRsvp() {
      this.confirmationMessage = "";
      this.isLoading = true;
      let isValid = true;

      // Valida todos los campos requeridos
      const fieldsToValidate = ['nombre', 'busLlegada', 'busVuelta', 'dormir'];
      if (this.formData.dormir === 'SI') {
        fieldsToValidate.push('desayuno');
      }
      fieldsToValidate.forEach(field => {
        if (!this.validateField(field)) isValid = false;
      });

      if (!isValid) {
        this.isLoading = false;
        return;
      }

      try {
        const selectedGuest = this.guestData[this.selectedGuestId];
        if (selectedGuest.confirmado) {
          throw new Error("Ya has confirmado tu asistencia. Gracias!");
        }

        // Guardar la confirmación de asistencia
        const rsvpRef = databaseRef(database, 'rsvps');
        const newRsvpRef = push(rsvpRef);
        const timestamp = Date.now();
        const fechaLegible = new Date(timestamp).toLocaleString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'Europe/Madrid'
        }).replace(',', '');

        const submittedFromMobile = this.isMobileDevice();
        if (this.formData.dormir === 'SI') {
          this.formData.busVuelta = 'NO';
        }

        await set(newRsvpRef, {
          ...this.formData,
          codigoInvitacion: this.guestIdentifier.toUpperCase().trim(),
          timestamp: fechaLegible,
          submittedFromMobile: submittedFromMobile,
        });
        console.log()
        // Ajuste en la referencia para actualizar 'confirmado'
        const guestConfirmRef = databaseRef(database, `guests/${this.guestIdentifier.toUpperCase().trim()}/invitados/${this.selectedGuestId}`);
        const updateData = { confirmado: true };
        // Verificar si el nombre del formulario es distinto al de la base de datos
        if (this.formData.nombre !== selectedGuest.nombre) {
            updateData.nombre = this.formData.nombre; // Actualizar el nombre
            const guestIndex = this.guestNames.findIndex(guest => guest.id === this.selectedGuestId);
          if (guestIndex !== -1) {
              this.guestNames[guestIndex].nombre = this.formData.nombre;
              // Actualizar selectedGuestName si es necesario
              this.selectedGuestName = this.formData.nombre;
          }
        }

        await update(guestConfirmRef, updateData);

        this.confirmationMessage = `¡Gracias, ${this.formData.nombre}! Tu asistencia ha sido confirmada. ¡Te esperamos en la boda!`;
        this.resetForm();

        // Actualizar la lista de invitados para reflejar el estado 'confirmado'
        this.formVisible = false;
        this.guestNames.find(guest => guest.id === this.selectedGuestId).confirmado = true;

        // Verificar si todos los invitados han confirmado
        this.checkAllGuestsConfirmed();
        if (this.allGuestsConfirmed)
        {
          this.showWarningAlert = false;
        }

      } catch (error) {
        console.error('Error updating confirmation status:', error);
        this.errorMessage = error.message || 'Hubo un problema al enviar tu confirmación. Por favor, intenta de nuevo más tarde.';
        this.formSubmitted = false;
      } finally {
        this.isLoading = false;
      }
    },
    resetForm() {
      // Reinicia los campos del formulario para permitir otra entrada
      this.showWarningAlert = false;
      this.formData = {
        nombre: '',
        alergia: '',
        busLlegada: '',
        busVuelta: '',
        dormir: '',
        desayuno: '',
        comentari: '',
      };
      this.formErrors = {
        nombre: false,
        dormir: false,
        desayuno: false,
        busLlegada: false,
        busVuelta: false,
      };
    }
  },
  watch: {
    // Observa cambios en 'formData.dormir'
    'formData.dormir'(newVal) {
      // Si se elige 'NO' para quedarse a dormir, reinicia la selección de desayuno
      if (newVal === 'NO') {
        this.formData.desayuno = '';
      }
      else if (newVal === 'SI') {
        this.formData.busVuelta = '';
      }
    },
  },
};
</script>

<style scoped>
  /* Style for card titles */
  .card-title {
    color: #3F4939; /* Set text color */
    font-family: 'CaudexBold'; /* Use specific font family */
    font-size: 1.5em; /* Set font size */
  }

  /* Style for confirmed guests */
  .is-confirmed {
    color: grey; /* Set text color for confirmed guests */
    text-decoration: line-through; /* Apply line-through decoration */
  }

  /* Style for card subtitles */
  .card-subtitle {
    color: #3F4939; /* Set text color */
    font-family: 'Avenir'; /* Use specific font family */
    font-weight: bold; /* Apply bold font weight */
  }

  /* Style for card buttons */
  .card-button {
    color: #3F4939; /* Set text color */
    font-family: 'Avenir'; /* Use specific font family */
    font-size: 0.9em; /* Set font size */
  }

  /* Style for custom card container */
  .custom-card {
    position: relative; /* Ensure relative positioning */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Apply box shadow */
    border-radius: 10px; /* Apply border radius */
    border: none; /* Remove border */
    padding: 20px; /* Apply padding */
    background-color: rgba(255, 255, 255, 0.92); /* Set semi-transparent background color */
  }

  /* Style for alerts container */
  .alerts-container {
    padding-top: 30px; /* Add padding to the top */
  }

  /* Style for back button */
  .back-button {
    padding: 0.25rem 0.5rem; /* Apply padding */
    color: inherit; /* Inherit text color */
    z-index: 1050; /* Set z-index */
    position: absolute; /* Set absolute positioning */
    top: 10px; /* Position from the top */
    left: 10px; /* Position from the left */
  }

  /* Hover effect for custom card */
  .custom-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Apply hover box shadow */
  }

  /* Style for thank you section */
  .thank-you-section {
    text-align: center; /* Align text to center */
    margin-top: 20px; /* Add margin to the top */
  }

  /* Style for thank you title */
  .thank-you-title {
    color: #3F4939; /* Set text color */
    font-family: 'CaudexBold', serif; /* Use specific font family */
    font-size: 1.8em; /* Set font size */
  }

  /* Style for thank you message */
  .thank-you-message {
    color: #3F4939; /* Set text color */
    font-family: 'Avenir', sans-serif; /* Use specific font family */
    margin: 10px 0 20px; /* Apply margin */
    font-size: 1.2em; /* Set font size */
  }

  /* Style for images container */
  .images-container {
    display: flex; /* Display as flex container */
    justify-content: center; /* Center children horizontally */
    flex-wrap: wrap; /* Allow wrapping */
    gap: 10px; /* Add gap between images */
  }

  /* Style for thank you gif */
  .thank-you-gif {
    width: auto; /* Adjust width automatically */
    max-width: 200px; /* Set maximum width */
    height: auto; /* Adjust height automatically */
  }

  /* Style for placeholder text */
  ::placeholder {
    color: #adb5bd; /* Set placeholder text color */
    opacity: 1; /* Ensure visibility */
    font-style: italic; /* Apply italic style to placeholder text */
  }

  .thank-you-section .faq-link {
    color: #0056b3;
    text-decoration: underline;
  }
</style>

