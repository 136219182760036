<template>
  <b-container fluid="lg" class="my-5">
    <b-row class="align-stretch">
      <!-- Contenedor para la tarjeta de imagen -->
      <b-col xs="12" md="6" class="mb-3 mb-md-0">
        <b-card class="img-card w-100" no-body>
          <b-card-img class="card-img" :src="require('@/assets/maleta_de_boda.webp')" alt="Maleta de Aventuras" top></b-card-img>
        </b-card>
      </b-col>

      <!-- Contenedor para la tarjeta de texto -->
      <b-col xs="12" md="6">
        <b-card class="custom-card w-100">
          <b-card-body>
            <b-card-title class="font-weight-bold mb-3">
              Billete a la Aventura
            </b-card-title>
            <b-card-text >
              Nuestra mayor ilusión es celebrar con {{ $t('vosotros') }} este día: <b>¡El mejor regalo es vuestra presencia!</b> ❤️
            </b-card-text>
            <b-card-text>
              Pero si aún así {{ $t('queréis') }} regalarnos algo, más que utensilios para el hogar, buscamos coleccionar momentos y experiencias 🌟 Por eso, en vez de la tradicional lista de regalos, hemos optado por abrir un "Billete a Nuestra Nueva Aventura" que nos llevará a cumplir sueños y proyectos de vida juntos.
            </b-card-text>
            <b-card-text>
              Si {{ $t('animáis') }} a contribuir a nuestra aventura, aquí {{ $t('dejamos') }} los detalles para realizar vuestro aporte. Cada gesto será una valiosa adición a nuestro viaje juntos y lo valoraremos eternamente 💖
            </b-card-text>
            <b-card-text>
              <b-icon icon="bank2" variant="info"></b-icon> <b>Número de Cuenta España:</b> ES20 0182 4383 9002 0197 5576
            </b-card-text>
            <b-card-text>
              <b-icon icon="bank2" variant="info"></b-icon> <b>Número de Cuenta Perú:</b> BBVA S/ 0011-0814-0202378167 <p>CCI: 011-814-000202378167-13</p>
            </b-card-text>
            <b-card-text>
              ¡Nos emociona vivir este día con {{ $t('vosotros') }} y agradecemos de corazón vuestro apoyo en esta nueva etapa juntos! 🌈
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Regalo",
};
</script>

<style scoped>
.img-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
}

.card-img {
  padding: 1px;
}

.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.92); /* Fondo semi-transparente para mejor legibilidad */
}

.custom-card .card-text {
  margin-bottom: 1rem; /* Espaciado entre párrafos */
  font-size: 1.1rem; /* Tamaño de fuente ajustado para legibilidad */
  color: #495057; /* Color que facilita la lectura */
}

.custom-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Efecto hover para profundidad adicional */
}

</style>
