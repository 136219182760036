<template>
  <div id="app" class="d-flex flex-column" style="min-height: 100vh;">
    <b-container fluid class="header-container px-0">
      <Header />
      <Navbar />
    </b-container>
  <router-view/>
  <Footer />
</div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Header,
    Footer
  },
  computed: {
    esDesdeEspana() {
      return this.$store.state.esDesdeEspana;
    },
  },
  watch: {
    esDesdeEspana: {
    immediate: true,
    handler(newValue) {
      this.$i18n.locale = newValue ? 'es' : 'esLatam';
    }
    },
  },
  created() {
    this.$store.dispatch('determinarUbicacion');
  },
};

</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1.2rem;
  min-height: 100vh; /* Altura mínima del viewport */
  display: flex;
  flex-direction: column;
}

.b-card-title {
  font-family: 'CaudexBold' !important;
  font-size: 1.5em !important;
  color: #3F4939;
}

body {
  background-image: url("./assets/Fondo.webp"); /* Asegúrate de que la ruta de la imagen sea correcta */
  background-position: center center; /* Centra la imagen en la pantalla */
  background-size: cover; /* Asegura que la imagen cubra toda la pantalla */
  background-attachment: fixed; /* Hace que la imagen de fondo sea fija en la vista */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

/* Estilo global para botones */
button, button.btn, .btn {
  background-color: #DBCFBE !important ; /* Color de fondo por defecto */
  color: black !important; /* Color de texto por defecto */
  border: 1px solid #DBCFBE !important; /* Borde del botón */
  border-radius: 5px !important; /* Bordes redondeados */
  font-weight: bold !important; /* Texto en negrita */
}

/* Estilo para el estado hover de los botones */
button:hover, .btn:hover {
  background-color: rgba(219, 207, 190, 0.5) !important; /* Color de fondo con transparencia en hover */
}

.header-container {
  height: 100%;
}
</style>

