<template>
  <b-container fluid>
    <b-row no-gutters class="mt-5">
      <!-- Imagen para dispositivos móviles -->
      <b-img src="@/assets/Principal_Mobile.webp" fluid alt="Main home image for cellphones" class="d-md-none"></b-img>
      <!-- Imagen para laptops/desktops -->
      <b-img src="@/assets/Principal.webp" fluid alt="Main home image for PC" class="d-none d-md-block"></b-img>
    </b-row>
    <slider></slider>
  </b-container>
</template>

<script>
import Slider from "../components/Slider.vue";

export default { 
  name: 'Home',
  components: {
    Slider
  }
};
</script>

<style scoped>

</style>
