import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Boda from '../views/Boda.vue'
import Location from '../views/Location.vue'
import Regal from '../views/Regal.vue'
import Photos from '../views/Photos.vue'
import Testimonios from '../views/Testimonials.vue'
import FAQS from '../views/FAQS.vue'
import Rsvp from '../views/Rsvp.vue'
import Information from '../views/Information.vue'
import GuestConfirmations from '../views/GuestConfirmations.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/home', 
  },
  {
    path: '/guestconfirmations',
    name: 'GuestConfirmations',
    component: GuestConfirmations,
  },
  {
    path: '/wedding',
    name: 'Boda',
    component: Boda,
  },
  {
    path: '/location',
    name: 'Location',
    component: Location,
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: FAQS,
  },
  {
    path: '/rsvp',
    name: 'Rsvp',
    component: Rsvp,
  },
  {
    path: '/present',
    name: 'Regal',
    component: Regal,
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: Testimonios,
  },
  {
    path: '/photos',
    name: 'Photos',
    component: Photos,
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
