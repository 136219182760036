<template>
  <b-container fluid="lg" class="guest-book-section my-4" style="max-width: 1300px;">
    <!-- Sección de Libro de Visitas -->
    <b-row>
      <b-col cols="12">
        <b-card class="text-center custom-card">
          <b-card-body>
            <b-card-title class="mb-3 font-weight-bold">
              Libro de Visitas
            </b-card-title>
            <!-- Mensaje de invitación -->
            <b-card-text class="invitation-message mb-3">
              ¡Tu firma cuenta! <br>
              Plasma tu energía y buenos deseos. Comparte una frase divertida 😄, un recuerdo especial 📸 o algo que quieras decirnos ❤️. Tu mensaje será un tesoro invaluable y nos llenará de mucha alegría. ¡Deja aquí tu huella y celebra con nosotros!
            </b-card-text>
            <!-- Botón para añadir nueva entrada -->
            <div class="d-flex justify-content-center mb-5">
              <b-button @click="showEntryForm" variant="primary" class="d-flex justify-content-center align-items-center">
                <span class="d-flex align-items-center">
                  <b-icon icon="pencil-square" variant="light" class="mr-2"></b-icon>
                  Añadir Entrada
                </span>
              </b-button>
            </div>
            <!-- Listado de entradas existentes -->
            <b-row>
              <b-col v-for="entry in paginatedEntries" :key="entry.id" lg="6">
                <b-card class="guest-book-card mb-3">
                  <blockquote class="blockquote">
                    {{ entry.message }}
                    <footer class="blockquote-footer">
                      <span class="name-and-date">
                        {{ entry.guestName }} 
                        <small class="text-muted">{{ formatDate(entry.date) }}</small>
                      </span>
                    </footer>
                  </blockquote>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div class="pagination-container">
      <b-pagination
        v-model="currentPage"
        :total-rows="guestBookEntries.length"
        :per-page="entriesPerPage"
        aria-controls="my-entries"
        class="b-pagination">
      </b-pagination>
    </div>
    <!-- Modal para nueva entrada -->
    <b-modal v-model="showForm" title="Nueva Entrada en el Libro de Visitas" hide-footer>
      <form @submit.prevent="submitGuestBookEntry">
        <b-form-group label="Nombre del invitado">
          <b-form-input v-model="newEntry.guestName" required></b-form-input>
        </b-form-group>
        <b-form-group label="Mensaje">
          <b-form-textarea v-model="newEntry.message" required rows="4"></b-form-textarea>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">
            <b-icon icon="cursor-fill" variant="light"></b-icon> Enviar Entrada
          </b-button>
        </div>
      </form>
    </b-modal>
  </b-container>
</template>


<script>
import { database } from '@/config/firebaseConfig.js';
import { ref as databaseRef, push, onValue } from 'firebase/database';

export default {
  name: 'GuestBook',
  data() {
    return {
      guestBookEntries: [],
      currentPage: 1,
      entriesPerPage: 5,
      showForm: false,
      newEntry: { guestName: '', message: '', date: new Date().toISOString() },
    };
  },
  computed: {
    paginatedEntries() {
      let reversedEntries = [...this.guestBookEntries].reverse();
      let start = (this.currentPage - 1) * this.entriesPerPage;
      let end = start + this.entriesPerPage;
      return reversedEntries.slice(start, end);
    }
  },
  created() {
    const guestBookRef = databaseRef(database, 'testimonials');
    onValue(guestBookRef, (snapshot) => {
      const guestBookData = snapshot.val();
      if (guestBookData) {
        this.guestBookEntries = Object.values(guestBookData);
      }
    });
  },
  methods: {
    showEntryForm() {
      this.showForm = true;
    },
    submitGuestBookEntry() {
      const guestBookRef = databaseRef(database, 'testimonials');
      push(guestBookRef, this.newEntry);
      this.newEntry = { guestName: '', message: '', date: new Date().toISOString() };
      this.showForm = false;
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
      return new Date(date).toLocaleString('es-ES', options);
    }
  },
};
</script>

<style>
.guest-book-section {
  padding: 20px;
  text-align: center;
}

.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.92);
}

.invitation-message {
  font-size: 1.0em;
}

.blockquote {
  font-size: 1.1rem;
}

.guest-book-card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-height: 200px; /* Establece una altura mínima */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pagination-container {
  position: sticky;
  bottom: 0;
  padding: 10px 0; /* Espacio adicional alrededor de la paginación */
}

.guest-book-card:hover {
  transform: translateY(-5px);
}

.blockquote-footer {
  margin-top: 15px;
  font-weight: bold;
  color: #555;
}

/* Estilos para la paginación */
.b-pagination {
  justify-content: center; /* Centra la paginación */
  margin-top: 20px; /* Espacio antes de la paginación */
}

.b-pagination .page-item .page-link {
  color:  #b2c2bf; /* Color de los números de página */
  background-color: #fff; /* Fondo de los botones de página */
  border-color:  #b2c2bf; /* Color del borde de los botones de página */
}

.b-pagination .page-item.active .page-link {
  color: #fff !important;
  background-color: #b2c2bf !important; /* Verde salvia suave como fondo */
  border-color:  #b2c2bf !important;
}

.b-pagination .page-item.disabled .page-link {
  color: #ccc; /* Color para los elementos deshabilitados */
}
.blockquote-footer {
  font-weight: bold;
  color: #555;
  text-align: center; /* Centrar el contenido del footer */
}

/* Estilo para el nombre y la fecha juntos */
.name-and-date {
  display: inline-block; /* Hacer que el contenedor se ajuste al contenido */
}

/* Estilos para el título y subtítulo de la tarjeta */
.custom-card .card-title {
  font-size: 1.5em; /* Tamaño de fuente más grande para el título */
  color: #3F4939;
  font-family: 'CaudexBold';
}

.custom-card .card-subtitle {
  margin-bottom: 1rem; /* Espaciado entre párrafos */
  font-size: 1.1rem; /* Tamaño de fuente ajustado para legibilidad */
  color: #495057; /* Color que facilita la lectura */
}

.blockquote-footer small {
  font-weight: normal;
  color: #888;
  margin-left: 10px; /* Espacio entre el nombre y la fecha */
}
</style>


