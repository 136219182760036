<template>
  <b-container fluid="lg" class="b-container my-4">
    <b-row>
      <b-col cols="12">
        <b-card-title class="Card-Title mb-4">
          Preguntas Frecuentes
        </b-card-title>
        <div id="faq-accordion" role="tablist">
          <!-- ¿A qué hora debo llegar? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card-header p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-2 variant="info" ref="horaButton">¿A qué hora debo llegar?</b-button>
            </b-card-header>
            <b-collapse id="accordion-2" role="tabpanel" @shown="scrollToElement('accordion-2')">
              <b-card-body>
                {{ $t('os4') }} pedimos llegar a las <b>12.30 horas</b> para {{ $t('acomodaros') }} y disfrutar de un momento juntos, previo a la ceremonia.
              </b-card-body>
            </b-collapse>
          </b-card>
          <!-- ¿Cómo puedo llegar? ¿El sitio cuenta con parking? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-1 variant="info" ref="comoLlegarButton">¿Cómo puedo llegar? ¿El sitio cuenta con parking?</b-button>
            </b-card-header>
            <b-collapse id="accordion-1" role="tabpanel" @shown="scrollToElement('accordion-1')">
              <b-card-body>
                <b-card-text>
                  <b>En coche:</b> Sellarès está a 1 hora, aproximadamente, de Barcelona ciudad. Dispone de un parking para más de 40 coches. 
                </b-card-text>
                <b-card-text>
                  <b>En bus:</b> Estamos coordinando un servicio de transporte para {{ $t('facilitaros') }} la llegada y el regreso. Si no marcaste esta opción al confirmar tu asistencia y tienes interés, por favor, escribe lo antes posible a ksilva.jruano@gmail.com
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <!-- Cómo llegar a la ermita desde el aparcamiento -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-8 variant="info" ref="ermitaButton">¿Cómo llegar a la ermita desde el aparcamiento?</b-button>
            </b-card-header>
            <b-collapse id="accordion-8" role="tabpanel" @shown="scrollToElement('accordion-8')">
              <b-card-body>
                <b-card-text>
                  La ermita se encuentra a tan solo 3 minutos caminando desde el aparcamiento de Sellarès. El trayecto es de 200 metros por una pista forestal sin desnivel que se puede hacer perfectamente a pie.
                </b-card-text>
                <b-card-text>
                  <b>Importante:</b> El aparcamiento de los coches será únicamente en Sellarès Espai pero para aquellas personas con movilidad reducida, se permitirá el acceso en coche hasta la misma ermita, donde se podrá aparcar al lado.
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <!-- ¿Puedo traer acompañante? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-5 variant="info" ref="acompañanteButton">¿Puedo traer acompañante?</b-button>
            </b-card-header>
            <b-collapse id="accordion-5" role="tabpanel" @shown="scrollToElement('accordion-5')">
              <b-card-body>
                <b-card-text>Nuestra boda es estrictamente por invitación para asegurarnos de que todos los que estén presentes sean parte de este momento tan especial.</b-card-text>
                <b-card-text>Por favor, {{ $t('revisad') }} invitación para confirmar el número de <b>asistentes permitidos</b> y {{ $t('vuestro') }} <b>código de confirmación</b>.</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <!-- ¿Qué pasa si tengo una restricción dietética? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-6 variant="info" ref="alergiaButton">¿Qué pasa si tengo alguna alergia o intolerancia alimentaria?</b-button>
            </b-card-header>
            <b-collapse id="accordion-6" role="tabpanel" @shown="scrollToElement('accordion-6')">
              <b-card-body>
                ¡No {{ $t('os5') }}! Queremos que todos {{ $t('disfruteis') }} de este día. Por favor, {{ $t('rellenad') }} información al confirmar asistencia para tener en cuenta cualquier necesidad especial.
              </b-card-body>
            </b-collapse>
          </b-card>
          <!-- ¿Cuándo es la fecha límite para confirmar asistencia? -->
          <!-- <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-4 variant="info" ref="fechaLimiteButton">¿Cuándo es la fecha límite para confirmar asistencia?</b-button>
            </b-card-header>
            <b-collapse id="accordion-4" role="tabpanel" @shown="scrollToElement('accordion-4')">
              <b-card-body>
                Por favor {{ $t('confirmad') }} {{ $t('vuestra2') }} asistencia antes del <b>1 de julio</b>. Es importante para nosotros contar con vuestra presencia y organizarnos de la mejor manera.
              </b-card-body>
            </b-collapse>
          </b-card> -->
          <!-- ¿Habrá Barra Libre de Bebidas? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-3 variant="info" ref="barraLibreButton">¿Habrá barra libre de bebidas?</b-button>
            </b-card-header>
            <b-collapse id="accordion-3" role="tabpanel" @shown="scrollToElement('accordion-3')">
              <b-card-body>
                ¡Por supuesto! Las bebidas corren por nuestra cuenta. {{ $t('vosotros2') }} solo {{ $t('teneis') }} que traer las ganas de celebrar. ¡{{ $t('preparaos') }} para disfrutar sin límites!
              </b-card-body>
            </b-collapse>
          </b-card>
          <!-- ¿Pueden asistir niños a la boda? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block href="#" v-b-toggle.accordion-7 variant="info" ref="ninñosButton">¿Pueden asistir niños a la boda?</b-button>
            </b-card-header>
            <b-collapse id="accordion-7" role="tabpanel" @shown="scrollToElement('accordion-7')">
              <b-card-body>
                <b-card-text>
                  Por necesidad más que por elección, solo podemos incluir a los peques que figuren expresamente en {{ $t('vuestra2') }} invitación.
                </b-card-text>
                <b-card-text>
                  ¡Esperamos {{ $t('vuestra2') }} comprensión! No obstante, entendemos que hay situaciones especiales, así que si este es tu caso, por favor háznoslo saber.
                </b-card-text>             
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FAQS",
  methods: {
    scrollToElement(collapseId) {
      this.$nextTick(() => {
        // Utiliza el ID para encontrar el b-collapse correspondiente
        const collapse = document.getElementById(collapseId);

        if (collapse) {
          // Se calcula si el b-collapse es completamente visible
          const collapseBottom = collapse.getBoundingClientRect().bottom;
          const isVisible = collapseBottom <= window.innerHeight;

          // Si el b-collapse no es completamente visible, realiza el scroll
          if (!isVisible) {
            collapse.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }
      });
    },
  }
};


</script>

<style scoped>
.b-container {
  padding: 20px 10px;
}

.b-collapse .card-header {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
}

.b-collapse .card-header .btn {
  color: #007bff;
  font-weight: bold;
}

.b-collapse .card-header .btn:hover {
  color: #0056b3; /* Color más oscuro en hover */
}

.Card-Title {
  font-family: 'CaudexBold';
  font-size: 1.5em;
  color: #3F4939;
}

.b-collapse .card-body {
  background-color: rgba(255, 255, 255, 0.92) !important; /* Fondo semi-transparente para mejor legibilidad */
  color: #343a40; /* Color de texto oscuro para legibilidad */
  padding: 15px;
  border-top: none; /* Elimina el borde superior para una apariencia más limpia */
}

/* Transiciones suaves para botones y acordeones */
.b-collapse .btn,
.b-collapse .card-body {
  transition: all 0.3s ease-in-out;
}

.b-collapse .card-body:hover {
  background-color: #f8f9fa; /* Cambia el fondo en hover para resaltar */
}

</style> 