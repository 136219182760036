<template>
  <b-container fluid="lg" class="my-5" style="max-width: 1300px">
    <b-row >
      <div class="video-background">
        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" ref="videoElement">
        </video>
      </div>
      <!-- Contenedor de la tarjeta de información -->
      <b-col xs="12" md="6" class="mb-3 mb-md-0">
        <b-card class="custom-card">
          <b-card-img :src="require('@/assets/sellares-rural2.webp')" alt="Sellares Espai Rural" top></b-card-img>
          <b-card-body>
            <b-card-title>Sellarès Rural</b-card-title>
            <b-card-sub-title class="mb-3 text-muted">Sallent, Bages</b-card-sub-title>
            <b-card-text>
              Comenzaremos un nuevo capítulo juntos y uniremos nuestras vidas en un lugar con un encanto único. ¡Celebra junto a nosotros este día tan especial!
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Contenedor para el iframe del mapa -->
      <b-col xs="12" md="6">
        <div class="map-container">
          <iframe
            id="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2972.3769532628708!2d1.9182447772552969!3d41.84171256769003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4feaf789984b1%3A0x823b0f46f2b1215a!2sSellar%C3%A8s%20Rural!5e0!3m2!1sen!2ses!4v1706279614745!5m2!1sen!2ses"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          >
          </iframe> 
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
export default {
  name: "Location",
  mounted() {
    this.loadVideoBasedOnDevice();
  },
  methods: {
    loadVideoBasedOnDevice() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const videoSource = isMobile ? require('@/assets/SellaresVideoMobile2.mp4') : require('@/assets/SellaresVideoPC.mp4');
      
      const sourceElement = document.createElement('source');
      sourceElement.setAttribute('src', videoSource);
      sourceElement.setAttribute('type', 'video/mp4');
      
      this.$refs.videoElement.appendChild(sourceElement);

      if (isMobile) {
        // Aplicar estilos adicionales para dispositivos móviles
        this.applyMobileVideoStyles();
        this.applyBlurEffect(this.$refs.videoElement, 3);
      } else {
        this.applyBlurEffect(this.$refs.videoElement, 6);
      }

      this.$refs.videoElement.load();
    },
    applyBlurEffect(videoElement, blurValue) {
      videoElement.style.webkitFilter = `blur(${blurValue}px)`; // Para Safari
      videoElement.style.filter = `blur(${blurValue}px)`; // Para otros navegadores
    },
    applyMobileVideoStyles() {
      // Ajustes de CSS para asegurar que el video ocupe toda la pantalla en dispositivos móviles
      this.$refs.videoElement.parentNode.style.position = 'fixed';
      this.$refs.videoElement.parentNode.style.top = '0';
      this.$refs.videoElement.parentNode.style.left = '0';
      this.$refs.videoElement.style.width = '100vw';
      this.$refs.videoElement.style.height = '100vh';
      this.$refs.videoElement.style.objectFit = 'cover'; // Asegura que el video cubra completamente la pantalla sin perder proporciones
    }
  }
};
</script>



<style>
.custom-card .card-title {
  color: #3F4939;
  /* color: #757575; */
}

.custom-card .card-subtitle {
  color: #3F4939;
  font-family: 'CaudexBold';
  font-size: 1.0em;
}

.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.75); /* Fondo semi-transparente para mejor legibilidad */
}

b-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Efecto hover para profundidad adicional */
}

.custom-card .card-text {
  color: #343a40; /* Color de texto más oscuro para mejorar la legibilidad */
  margin-bottom: 15px; /* Espaciado consistente entre párrafos */
  margin-bottom: 1rem; /* Espaciado entre párrafos */
  font-size: 1.1rem; /* Tamaño de fuente ajustado para legibilidad */
}

.map-container {
  padding: 10px; /* Espacio alrededor del iframe para el fondo semi-transparente */
  border-radius: 10px; /* Bordes redondeados para el contenedor */
  background-color: rgba(255, 255, 255, 0.85); /* Fondo semi-transparente */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Opcional: añadir sombra para consistencia con b-card */
  overflow: hidden; /* Asegura que el iframe también tenga bordes redondeados */
}

.map-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Efecto hover para profundidad adicional */
}

iframe#maps {
  width: 100%; /* Ancho completo dentro del contenedor */
  height: 500px; /* Altura especificada */
  border: none; /* Remueve el borde predeterminado */
  border-radius: 10px; /* Bordes redondeados para el iframe */
}

.video-background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  width: auto; 
  height: auto; 
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: auto;
}

</style>

