import Vue from 'vue'
import App from './App.vue'
import BootstrapVue, { IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import router from './router'
import i18n from './i18n' 
import store from './store';
import './assets/css/fonts.css'
import VueLazyload from 'vue-lazyload'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin);
Vue.use(VueLazyload)
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')