<template>
  <b-container fluid="lg" class="my-5" style="max-width: 1300px;">
    <b-row class="align-stretch">
      <!-- Contenedor de las tarjetas de detalles y código de vestimenta -->
      <b-col xs="12" md="6" class="mb-3 mb-md-0">
        <!-- Contenedor externo que actúa como una única tarjeta para propósitos de layout -->
        <div class="h-100">
          <b-card class="custom-card mb-3">
            <b-card-body>
              <b-card-title>Detalles de la Boda</b-card-title>
              <b-card-text class="mt-4"><strong>Fecha:</strong> Sábado 12 de Octubre de 2024</b-card-text>
              <b-card-text><strong>Hora:</strong> 12:30 horas</b-card-text>
              <b-card-text>
                <span><strong>Lugar:</strong> Sellarès Rural, Camí de Sant Pere de Serrahïma s/n, 08650 Sallent, Barcelona.</span>
                <!-- <b-img :src="require('@/assets/Masia.webp')" alt="Sellarès Rural" class="ml-3" style="width: 100px; height: auto;"></b-img> -->
              </b-card-text>
              <b-card-text>Sallent es un encantador pueblo en la comarca catalana del Bages, a aproximadamente 1 hora de Barcelona ciudad.</b-card-text>
              <b-card-text>Aquí podrás encontrar cómo llegar: <router-link to="/location" class="card-link">instrucciones de llegada.</router-link></b-card-text>
              <!-- Imagen de la masía en formato pequeño -->
              <div class="mt-4 align-items-center justify-content-center" style="max-width: 100px; margin-left: auto; margin-right: auto;">
                <b-img :src="require('@/assets/Masia.webp')" alt="Sellarès Rural" fluid style="width: 100%;"></b-img>
              </div>
              <!-- Botón Guardar en el Calendario -->
              <!-- <div class="d-flex justify-content-center mt-3">
              <b-button variant="outline-primary" class="align-items-center justify-content-center btn-save-calendar" @click="addToCalendar">
                <b-icon icon="calendar-plus" aria-hidden="true"></b-icon>
                Guardar en el Calendario
              </b-button>
              </div> -->
              <div class="d-flex justify-content-center mt-4">
              <b-button variant="outline-primary" class="btn-save-calendar d-flex justify-content-center align-items-center" @click="addToCalendar">
                <span class="d-flex align-items-center">
                  <b-icon icon="calendar-plus" aria-hidden="true" class="mr-2"></b-icon>
                  Guardar en el Calendario
                </span>
              </b-button>
            </div>
            </b-card-body>
          </b-card>
          <b-card class="custom-card">
            <b-card-body>
              <b-card-title>Código de Vestimenta</b-card-title>
              <b-card-text>
              ¡{{ $t('acompañadnos') }} a celebrar este día con un atuendo que {{ $t('os') }} haga sentir radiantes y elegantes! Recomendamos un estilo formal.
              </b-card-text>
              <!-- Imagen de un cámara en formato pequeño -->
              <div class="mt-4 align-items-center justify-content-center mb-3" style="max-width: 100px; margin-left: auto; margin-right: auto;">
              <b-img :src="require('@/assets/Dresscode.webp')" alt="Código Vestimenta" fluid style="width: 100%;"></b-img>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </b-col>

      <!-- Columna para la tarjeta de la imagen -->
      <b-col xs="12" md="6">
        <b-card class="custom-card">
          <b-card-img :src="require('@/assets/Programa_Boda.webp')" alt="Programa Boda"></b-card-img>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
export default {
  name: "Boda",
  components: {},
  methods: {
    addToCalendar() {
      const details = {
        title: "Boda Karen&Jose",
        start: "2024-10-12T12:30:00",
        end: "2024-10-12T23:00:00",
        location: "Sellarès Rural, Camí de Sant Pere de Serrahïma s/n, 08650 Sallent, Barcelona",
        details: "Celebración de nuestra boda 💍👰🤵 ¡Te esperamos! 🥰",
      };

      const baseUrl = "https://calendar.google.com/calendar/render";
      const dateTimeFormat = (date) => date.replace(/-|:|\.\d{3}/g, "");
      const params = `action=TEMPLATE&text=${encodeURIComponent(details.title)}&dates=${dateTimeFormat(details.start)}/${dateTimeFormat(details.end)}&details=${encodeURIComponent(details.details)}&location=${encodeURIComponent(details.location)}&sf=true&output=xml`;

      const googleCalendarUrl = `${baseUrl}?${params}`;

      window.open(googleCalendarUrl, '_blank');
    },
  }
};
</script>

<style scoped>
.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.92);
}

.custom-card .full-img img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
}


.custom-card .card-title {
  color: #495057; /* Color que facilita la lectura */
  font-weight: bold; /* Mayor énfasis */
}

.card-link {
  color: #0056b3; /* Asegura un color que contraste bien con el fondo */
}

.custom-card .card-text {
  font-family: 'Avenir';
  margin-bottom: 1rem; /* Espaciado entre párrafos */
  font-size: 1.1rem; /* Tamaño de fuente ajustado para legibilidad */
  color: #495057; /* Color que facilita la lectura */
}
.btn-save-calendar {
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-family: 'Avenir';
}

</style>
