import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDDor4_ww0gwWK8KqQGSu35i5r4SqStGPg",
    authDomain: "diamagic-89acb.firebaseapp.com",
    databaseURL: "https://diamagic-89acb-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "diamagic-89acb",
    storageBucket: "diamagic-89acb.appspot.com",
    messagingSenderId: "1054461609846",
    appId: "1:1054461609846:web:d386d84b9eacf66505dc57"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Firebase Realtime Database instance
const database = getDatabase(app);

const auth = getAuth(app);

const getRsvps = async () => {
    const dbRef = ref(database, 'rsvps/');
    try {
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
            const rsvpsData = snapshot.val();
            // Convertir el objeto de datos a un array incluyendo el ID
            const rsvpsArray = Object.keys(rsvpsData).map(key => ({
                id: key,
                ...rsvpsData[key],
            }));
            return rsvpsArray;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error al obtener RSVPs: ", error);
        throw error;
    }
};

export { app as firebaseApp, database, auth, getRsvps };