import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    esDesdeEspana: false, // Aquí guardas el valor booleano
  },
  mutations: {
    actualizarUbicacion(state, esDesdeEspana) {
      state.esDesdeEspana = esDesdeEspana;
    },
  },
  actions: {
    determinarUbicacion({ commit }) {
        let esDesdeEspana = false;

        fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          if (data.country === 'ES') {
            // Usuario en España
            esDesdeEspana = true;
          } else {
            // Usuario fuera de España, se asume español latinoamericano
            esDesdeEspana = false;
          }  
          // El commit se mueve aquí para asegurarse de que se ejecute después de obtener la respuesta
          commit('actualizarUbicacion', esDesdeEspana);
        })
        .catch(error => {
          console.error('Hubo un error con la API de geolocalización:', error);
          // Considera manejar el error de manera adecuada, tal vez estableciendo un valor por defecto
          commit('actualizarUbicacion', esDesdeEspana);
        });
    },
  },
});
