<template>
  <b-container fluid class="my-4 p-0">
    <b-row>
      <b-col xs="12" lg="6" class="mb-4">
        <b-carousel id="carousel-1" :interval="4000" controls indicators background="#ababab" img-width="1024" img-height="480">
          <b-carousel-slide v-for="(slide, index) in slides" :key="index" :img-src="slide.src" :img-alt="`Carousel Slide ${index + 1}`"></b-carousel-slide>
        </b-carousel>
      </b-col>
      <b-col xs="12" lg="6">
        <!-- <b-card class="custom-card">
          <b-card-title class="custom-card-title">Nuestra Gran Aventura Comienza</b-card-title>
          <b-card-sub-title class="mb-3 custom-card-sub-title">¡Bienvenidos a nuestra boda!</b-card-sub-title>
          <b-card-text>
            Cada instante que hemos compartido ha sido un paso hacia este hermoso viaje que emprenderemos juntos. Estamos emocionados de invitaros a ser parte de nuestro día especial: nuestra boda.
          </b-card-text>
          <b-card-text>
            En cada sonrisa, en cada mirada, se esconde una historia que hemos construido juntos. Y ahora, queremos compartir con vosotros la alegría de este nuevo capítulo. ¡Os esperamos con los brazos abiertos y corazones llenos de amor!
          </b-card-text>
        </b-card> -->
        <b-card class="custom-card">
          <b-card-title class="custom-cardsrc/components/Slider.vue-title">Comienza Nuestra Aventura</b-card-title>
          <b-card-sub-title class="mb-3 custom-card-sub-title">
            Faltan <span class="days-counter">{{ daysUntilWedding }}</span> días para nuestra boda 💍💒
          </b-card-sub-title>
          <b-card-text>
            ¡Hola, familia y amigos! 🥰 Estamos muy felices de poder compartir con {{ $t('vosotros') }} uno de los días más esperados de nuestras vidas. Hemos creado este espacio con mucho cariño, pensando en {{ $t('ofreceros') }} una ventana a los preparativos, emociones y detalles de nuestra boda.
          </b-card-text>
          <b-card-text>
            Aquí {{ $t('encontrareis') }} toda la información esencial sobre la celebración: cómo llegar, el programa del día, <router-link to="/rsvp" class="card-link">el formulario de confirmación</router-link>, y consejos para disfrutar al máximo de este día tan especial. Pero más allá de los detalles prácticos, queremos compartir con {{ $t('vosotros') }} la ilusión y el amor que nos ha llevado hasta aquí.
          </b-card-text>
          <b-card-text>
            {{ $t('vuestra') }} presencia es el regalo más grande y el complemento perfecto para este día. Contamos el tiempo para poder celebrarlo juntos.
          </b-card-text>
          <b-card-text>
            ¡{{ $t('preparaos') }} para un día lleno de alegría, amor y, sobre todo, mucha diversión!
          </b-card-text>
          <b-card-text>
            Con todo nuestro amor e ilusión, <i>Karen y Jose</i>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      slides: [
        { src: require('@/assets/slideshow/Home_1a.webp') },
        { src: require('@/assets/slideshow/Home_4.webp') },
        { src: require('@/assets/slideshow/Home_5.webp') },
        { src: require('@/assets/slideshow/Home_6.webp') },
        { src: require('@/assets/slideshow/Home_7.webp') },
        { src: require('@/assets/slideshow/Home_3.webp') },
        { src: require('@/assets/slideshow/Home_12.webp') },
        { src: require('@/assets/slideshow/Home_8.webp') },
        { src: require('@/assets/slideshow/Home_9.webp') },
        { src: require('@/assets/slideshow/Home_10.webp') },
        { src: require('@/assets/slideshow/Home_11.webp') },
        { src: require('@/assets/slideshow/Home_13.webp') }
      ],
      daysUntilWedding: this.calculateDaysUntilWedding(),
    };
  },
  methods: {
    calculateDaysUntilWedding() {
      const today = new Date();
      const weddingDate = new Date('2024-10-12');
      const timeDiff = weddingDate.getTime() - today.getTime();
      const days = Math.floor(timeDiff / (1000 * 3600 * 24));
      return days;
    },
  },
  mounted() {
    this.daysUntilWedding = this.calculateDaysUntilWedding();
  },
};
</script>

<style scoped>
.custom-carousel .b-carousel-slide img {
  width: 100%;
  height: auto;
}

.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.92); /* Fondo semi-transparente para mejor legibilidad */
}

.custom-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Efecto hover para profundidad adicional */
}

/* Aumentar el tamaño y mejorar el espacio alrededor de los indicadores de slide */
.b-carousel .carousel-indicators li {
  width: 48px;
  height: 48px;
  margin: 0 12px; /* Ajuste para proporcionar espacio adicional */
  background-color: #007bff;
}

/* Aumentar el tamaño de los botones de navegación y ajustar el ícono dentro */
.b-carousel .carousel-control-prev,
.b-carousel .carousel-control-next {
  width: 48px !important;
  height: 48px !important;
  margin: -24px 0; /* Ajuste el margen si es necesario para alinear correctamente */
}

.b-carousel .carousel-control-prev-icon,
.b-carousel .carousel-control-next-icon {
  width: 30px;
  height: 30px;
  background-size: contain; /* Asegura que el ícono se ajuste dentro del botón sin ser recortado */
}


.custom-card .custom-card-title {
  color: #3F4939;
}

.custom-card .card-text {
  font-family: 'Avenir';
  font-size: 1.1rem;
  padding: 1px;
}

.custom-card .custom-card-sub-title {
  color: #3F4939;
}

/* Target the custom carousel class and apply b-carousel-inner styles */
.custom-carousel .b-carousel-slide img{
  max-width: 100%; /* Ensure images don't exceed their container width */
  height: auto; /* Maintain aspect ratio while adjusting width */
  width: 100%; /* Make images fill the entire container width */
}

.b-carousel-slide {
  text-shadow: 1px 1px 2px #333; /* Mejor legibilidad de los subtítulos */
}

.card-link {
  color: #0056b3; /* Asegura un color que contraste bien con el fondo */
}

.days-until-wedding 
{
  color: #495057; /* Un color de texto que contraste bien */
}

.days-counter {
  font-weight: bold;
  color: #818d61; /* Un color que destaque para el número de días */
}

/* Responsividad del texto */
@media (max-width: 576px) {
  .days-until-wedding h4 {
    font-size: 0.8rem; /* Más pequeño en dispositivos móviles */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .days-until-wedding h4 {
    font-size: 1.2rem; /* Un poco más grande en tablets */
  }
}

@media (min-width: 769px) {
  .days-until-wedding h4 {
    font-size: 1.4rem; /* Tamaño estándar para pantallas grandes */
  }
}
</style>
