import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  es: {
    vosotros: "vosotros",
    vosotros2: "Vosotros",
    ofreceros: "ofreceros",
    encontrareis: "encontrareis",
    vuestra: "Vuestra",
    vuestra2: "vuestra",
    vuestro: "vuestro",
    preparaos: "Preparaos",
    acompañadnos: "Acompañadnos",
    os: "os",
    os2: "que no se os olvide",
    viajais: "viajáis",
    disfruteis: "disfrutéis",
    perderos: "perderos",
    os3: "os sintáis",
    os4: "Os",
    os5: "os preocupéis",
    os6: "os",
    dudeis: "dudéis",
    acomodaros: "acomodaros",
    facilitaros: "facilitaros",
    revisad: "revisad vuestra",
    rellenad: "rellenad vuestra",
    confirmad: "confirmad",
    teneis: "tenéis",
    confirmacion: "Has confirmado",
    confirmacion2: "Todos habéis confirmado",
    veros: "os a todos",
    queréis: "queréis",
    animáis: "os animáis",
    dejamos: "os dejamos",
    deseais: "deseáis",
    venis: "venís",
    podreis: "podréis",
    osapetezca: "os apetezca",
    quedaros: "quedaros", 
  },
  esLatam: {
    vosotros: "ustedes",
    vosotros2: "Ustedes",
    ofreceros: "ofrecerles",
    encontrareis: "encontrarán",
    vuestra: "Su",
    vuestra2: "su",
    vuestro: "su",
    preparaos: "Prepárense",
    acompañadnos: "Acompáñennos",
    os: "los",
    os2: "no olviden",
    viajais: "viajan",
    disfruteis: "disfruten",
    perderos: "perderse",
    os3: "se sientan",
    os4: "Les",
    os5: "se preocupen",
    os6: "les",
    dudeis: "duden",
    acomodaros: "acomodarlos",
    facilitaros: "facilitarles",
    revisad: "revisen su",
    rellenad: "completen vuestra",
    confirmad: "confirmen",
    teneis: "tienen",
    confirmacion: "Has confirmado",
    confirmacion2: "Todos han confirmado",
    veros: "los a todos",
    queréis: "quieren",
    animáis: "se animan",
    dejamos: "les dejamos",
    deseais: "desean",
    venis: "vienen",
    podreis: "podrán",
    osapetezca: "quieran",
    quedaros: "quedarse",    
  }
};

const i18n = new VueI18n({
    // Detecta el idioma del navegador o establece 'es' como predeterminado
    locale: navigator.language.startsWith('es') ? (navigator.language.includes('ES') ? 'es' : 'esLatam') : 'es',
    fallbackLocale: 'es',
    messages,
  });

export default i18n;