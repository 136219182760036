<template>
    <b-container fluid="lg" class="my-5" style="max-width: 2000px;">
      <b-row class="justify-content-center">
        <b-col cols="12">
            <!-- <b-col cols="12" sm="10" md="8"> -->
          <b-card class="custom-card">
            <div v-if="isLoggedIn" class="d-flex flex-column flex-md-row justify-content-center align-items-center my-2">
            <b-button class="d-flex align-items-center mx-1 my-1 btn-block btn-block-mobile" @click="exportToExcel" variant="success">
              <b-icon icon="file-spreadsheet" class="mr-2"></b-icon>
              <span class="d-md-inline">Exportar RSVPs a Excel</span>
            </b-button>
            <b-button class="d-flex align-items-center mx-1 my-1 btn-block btn-block-mobile" @click="exportTestimonialsToExcel" variant="success">
              <b-icon icon="file-earmark-excel" class="mr-2"></b-icon>
              <span class="d-md-inline">Exportar Libro de Visitas a Excel</span>
            </b-button>
            <b-button @click="updateData" variant="primary" class="d-flex align-items-center mx-1 my-1 btn-block btn-block-mobile">
              <b-icon icon="arrow-clockwise" class="mr-2"></b-icon>
              <span class="d-md-inline">{{ updateButtonText }}</span>
            </b-button>
            <b-button class="d-flex align-items-center mx-1 my-1 btn-block btn-block-mobile" @click="showAddInviteeModal" variant="primary">
              <b-icon icon="person-plus-fill" class="mr-2"></b-icon>
              <span class="d-md-inline">Añadir Invitado</span>
            </b-button>
            <b-button @click="signOut" variant="danger" class="d-flex align-items-center mx-1 my-1 btn-block btn-block-mobile">
              <b-icon icon="power" class="mr-2"></b-icon>
              <span class="d-md-inline">Cerrar sesión</span>
            </b-button>
          </div>
          <b-alert v-if="addInviteeSuccess" variant="success" show dismissible @dismissed="addInviteeSuccess = false">
            Unidad familiar añadida con éxito.
          </b-alert>
          <b-alert v-if="deleteInviteeSuccess" variant="success" show dismissible @dismissed="deleteInviteeSuccess = false">
            Invitado eliminado con éxito.
          </b-alert>
          <b-alert v-if="updateInviteeSuccess" variant="success" show dismissible @dismissed="updateInviteeSuccess = false">
            Invitado actualizado con éxito.
          </b-alert>
          <!-- <div v-if="isUpdating" class="text-center">
            <img src="@/assets/ThankYouCute.gif" alt="Actualizando..." style="width: 100px; height: auto;">
            <img src="@/assets/ThankYouCute2.gif" alt="Actualizando..." style="width: 100px; height: auto;">
          </div> -->
          <h2 class="text-center mt-4 my-3">Gestor de Confirmaciones</h2>
          <div v-if="!isLoggedIn || rsvps.length === 0">
            <p v-if="!isLoggedIn">Por favor, inicia sesión para ver las confirmaciones.</p>
            <p v-else>No hay datos disponibles.</p>
            <b-form @submit.prevent="signIn" v-if="!isLoggedIn">
                <b-form-group label="Email:" label-for="email-input">
                  <b-form-input id="email-input" type="email" v-model="email" required placeholder="Introduce tu email"></b-form-input>
                </b-form-group>
                <b-form-group label="Contraseña:" label-for="password-input">
                  <b-form-input id="password-input" type="password" v-model="password" required placeholder="Introduce tu contraseña"></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">Iniciar sesión</b-button>
              </b-form>
            </div>
            <b-alert class="mt-3" v-if="errorMessage" variant="danger" show dismissible @dismissed="errorMessage = ''">
            {{ errorMessage }}
            </b-alert>
            <div v-if="isLoggedIn">
              <b-button v-b-toggle.stats-collapse class="my-2">
                Mostrar/Ocultar Estadísticas
              </b-button>
              <b-collapse id="stats-collapse" class="mt-2">
              <b-card>
                <h3 class="text-center">Estadísticas de la Boda</h3>
                <b-row>
                  <b-col cols="12" md="2">
                    <b-card class="mb-3"  header-class="bg-primary-soft text-white" footer-class="bg-light">
                        <template #header>
                            <b-icon icon="people-fill"></b-icon> Total de Invitados
                        </template>
                        <b-card-text class="text-center display-4">
                            {{ mergedGuestList.length }}
                        </b-card-text>
                        </b-card>
                  </b-col>
                    <!-- Tarjeta de Invitados Confirmados -->
                    <b-col cols="12" md="2">
                        <b-card class="mb-3"  header-class="bg-success-soft text-white" footer-class="bg-light">
                        <template #header>
                            <b-icon icon="check-circle-fill"></b-icon> Confirmados
                        </template>
                        <b-card-text class="text-center display-4 text-sm">
                          {{ confirmedGuests }} ({{ (confirmedGuests / mergedGuestList.length * 100).toFixed(2) }}%)
                        </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- Tarjeta de Invitados Rechazados -->
                    <b-col cols="12" md="2">
                        <b-card class="mb-3"  header-class="bg-danger-soft text-white" footer-class="bg-light">
                        <template #header>
                            <b-icon icon="x-circle-fill"></b-icon> Rechazados
                        </template>
                        <b-card-text class="text-center display-4">
                            {{ unconfirmedGuests }} ({{ (unconfirmedGuests / mergedGuestList.length * 100).toFixed(2) }}%)
                        </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- Tarjeta de Respuestas Pendientes -->
                    <b-col cols="12" md="2">
                        <b-card class="mb-3 "  header-class="bg-warning-soft text-dark" footer-class="bg-light">
                        <template #header>
                            <b-icon icon="question-circle-fill"></b-icon> Respuestas Pendientes
                        </template>
                        <b-card-text class="text-center display-4">
                            {{ pendingGuests }} ({{ (pendingGuests / mergedGuestList.length * 100).toFixed(2) }}%)
                        </b-card-text>
                        </b-card>
                    </b-col>              

                    <!-- Tarjeta de Invitados con Alergias -->
                    <b-col cols="12" md="2">
                        <b-card class="mb-3"  header-class="bg-warning-soft text-dark" footer-class="bg-light">
                        <template #header>
                            <b-icon icon="exclamation-triangle-fill"></b-icon> Con Alergias
                        </template>
                        <b-card-text class="text-center display-4">
                            {{ invitadosConAlergias }} ({{ (invitadosConAlergias / confirmedGuests * 100).toFixed(2) }}%)
                        </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- Tarjeta de Invitados que se Quedan a Dormir -->
                    <b-col cols="12" md="2">
                      <b-card class="mb-3" header-class="bg-light-blue text-white" footer-class="bg-light">
                        <template #header>
                          <b-icon icon="moon-fill"></b-icon> Se quedan a dormir
                        </template>
                        <b-card-text class="text-center display-4">
                          {{ invitadosQuedanDormir }} ({{ (invitadosQuedanDormir / confirmedGuests * 100).toFixed(2) }}%)
                        </b-card-text>
                      </b-card>
                    </b-col>

                    <!-- Tarjeta de Invitados que Desayunarán -->
                    <b-col cols="12" md="2">
                      <b-card class="mb-3" header-class="bg-light-green text-white" footer-class="bg-light">
                        <template #header>
                          <b-icon icon="cup-straw"></b-icon> Desayunarán
                        </template>
                        <b-card-text class="text-center display-4">
                          {{ invitadosDesayunan }} ({{ (invitadosDesayunan / confirmedGuests * 100).toFixed(2) }}%)
                        </b-card-text>
                      </b-card>
                    </b-col>

                      <!-- Tarjeta de Transporte Requerido Ida -->
                    <b-col cols="12" md="2">
                        <b-card class="mb-3 small-text"  header-class="bg-info-soft text-white" footer-class="bg-light">
                        <template #header>
                            <b-icon icon="truck"></b-icon> Transporte Requerido Ida
                        </template>
                        <b-card-text class="text-center display-4">
                            {{ transporteRequeridoIda }} ({{ (transporteRequeridoIda / confirmedGuests * 100).toFixed(2) }}%)
                        </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- Tarjeta de Transporte Requerido Vuelta -->
                    <b-col cols="12" md="2">
                        <b-card class="mb-3 small-text"  header-class="bg-info-soft text-white" footer-class="bg-light">
                        <template #header>
                            <b-icon icon="truck"></b-icon> Transporte Requerido Vuelta
                        </template>
                        <b-card-text class="text-center display-4">
                            {{ transporteRequeridoVuelta }} ({{ (transporteRequeridoVuelta / confirmedGuests * 100).toFixed(2) }}%)
                        </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- Tarjeta de Confirmaciones desde Móvil -->
                    <b-col cols="12" md="2">
                      <b-card class="mb-3 small-text" header-class="bg-purple text-white" footer-class="bg-light">
                          <template #header>
                              <b-icon icon="phone-fill"></b-icon> Confirmados desde Móvil
                          </template>
                          <b-card-text class="text-center display-4">
                              {{ mobileSubmissions }} ({{ (mobileSubmissions / confirmedGuests * 100).toFixed(2) }}%)
                          </b-card-text>
                      </b-card>
                    </b-col>

                    <!-- Tarjeta de Confirmaciones desde PC -->
                    <b-col cols="12" md="2">
                      <b-card class="mb-3 small-text" header-class="bg-purple text-white" footer-class="bg-light">
                          <template #header>
                              <b-icon icon="laptop-fill"></b-icon> Confirmados desde PC
                          </template>
                          <b-card-text class="text-center display-4">
                              {{ pcSubmissions }} ({{ (pcSubmissions / confirmedGuests * 100).toFixed(2) }}%)
                          </b-card-text>
                      </b-card>
                    </b-col>

                </b-row>
              </b-card>
            </b-collapse>
                <b-form-group class="mt-3 text-left" label="Buscar nombre o código de invitado:">
                    <b-form-input v-model="searchQuery" placeholder="Ingresa el nombre o el código del invitado"></b-form-input>
                </b-form-group>
                <b-table class="table-responsive custom-table"
                        :items="paginatedData"
                        :fields="fields"
                        @sorted="onSorted"
                        striped
                        hover
                        responsive="sm"
                      >
                    <template #cell(alergia)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(busLlegada)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(busVuelta)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(codigoInvitacion)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(desayuno)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(dormir)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(nombre)="data">
                      <span @click="openEditModal(data.item)" class="editable-name">
                        {{ data.value }}
                        <b-icon icon="pencil" aria-hidden="true"></b-icon>
                      </span>
                    </template>
                    <template #cell(comentari)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(submittedFromMobile)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template #cell(timestamp)="data">
                    {{ data.value || 'N/A' }}
                    </template>
                    <template v-slot:cell(confirmado)="data">
                      <div class="d-flex justify-content-center">
                      <b-dropdown variant="light" class="d-flex justify-content-center align-items-center" size="sm" style="background-color: none !important; border: none !important;">
                        <template v-slot:button-content>
                          <b-icon v-if="data.item.confirmado === 'Sí'" icon="check-circle-fill" variant="success"></b-icon>
                          <b-icon v-else-if="data.item.confirmado === 'No'" icon="x-circle-fill" variant="danger"></b-icon>
                          <b-icon v-else icon="question-circle-fill" variant="info"></b-icon>
                        </template>
                        <b-dropdown-item @click="confirmar(data.item.codigoInvitacion, data.item.id)">Confirmar</b-dropdown-item>
                        <b-dropdown-item @click="rechazar(data.item.codigoInvitacion, data.item.id)">Rechazar</b-dropdown-item>
                        <b-dropdown-item @click="pendiente(data.item.codigoInvitacion, data.item.id)">Pendiente</b-dropdown-item>
                      </b-dropdown>
                    </div>
                    </template>

                  </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                  aria-controls="my-table"
                ></b-pagination>

            </div>          
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="add-invitee-modal" title="Añadir Nueva Unidad Familiar" @hide="resetNewInvitee" hide-footer>
      <b-form @submit.prevent="addInvitee">
        <div v-for="(invitee, index) in newInvitee.invitados" :key="index" class="invitee-entry">
          <b-form-group :label="'Nombre del invitado ' + (index + 1) + ':'" :label-for="'invitee-name-input-' + index">
            <b-form-input :id="'invitee-name-input-' + index" v-model="invitee.nombre" required placeholder="Nombre del invitado"></b-form-input>
          </b-form-group>
          <b-form-group :label="'Confirmado ' + (index + 1) + ':'" :label-for="'invitee-confirmed-input-' + index">
            <b-form-select :id="'invitee-confirmed-input-' + index" v-model="invitee.confirmado" required :options="['Sí', 'No', 'Pendiente']"></b-form-select>
          </b-form-group>
          <b-button @click="removeInviteeField(index)" variant="danger" class="mb-3">Eliminar</b-button>
        </div>
        <b-button @click="addInviteeField" variant="info" class="mb-3">Añadir otro invitado</b-button>
        <b-card class="mt-4 p-3 family-section">
          <h5 class="text-center">Campos de Unidad Familiar</h5>
        <b-form-group label="Preguntas Especiales:" label-for="new-invitee-special-questions-input">
          <b-form-select id="new-invitee-special-questions-input" v-model="newInvitee.preguntasEspeciales" required :options="['Sí', 'No']"></b-form-select>
        </b-form-group>
        <b-form-group label="Nota de Costo por Dormir:" label-for="new-invitee-sleep-cost-note-input">
          <b-form-select id="new-invitee-sleep-cost-note-input" v-model="newInvitee.notaCostoDormir" required :options="['Sí', 'No']"></b-form-select>
        </b-form-group>
      </b-card>
        <b-button type="submit" variant="success" class="mt-3">Añadir</b-button>
      </b-form>
    </b-modal>

    <b-modal id="edit-invitee-modal" title="Editar Invitado" @hide="resetCurrentInvitee" hide-footer>
      <b-form @submit.prevent="updateInvitee">
        <b-form-group label="Nombre del invitado:" label-for="invitee-name-input">
          <b-form-input id="invitee-name-input" v-model="currentInvitee.nombre" required placeholder="Nombre del invitado"></b-form-input>
        </b-form-group>

        <b-form-group label="Confirmado:" label-for="invitee-confirmed-input">
          <b-form-select id="invitee-confirmed-input" v-model="currentInvitee.confirmado" required :options="['Sí', 'No', 'Pendiente']"></b-form-select>
        </b-form-group>

        <div v-if="currentInvitee.noRsvp">
          <p class="text-center text-muted">El invitado no ha enviado aún el RSVP.</p>
        </div>
        <div v-else>
          <b-form-group label="Alergias:" label-for="invitee-allergy-input">
            <b-form-input id="invitee-allergy-input" v-model="currentInvitee.alergia" placeholder="Alergias"></b-form-input>
          </b-form-group>

          <b-form-group label="Bus de Llegada:" label-for="invitee-bus-arrival-select">
            <b-form-select id="invitee-bus-arrival-select" v-model="currentInvitee.busLlegada" :options="[{ value: 'SI', text: 'Sí' }, { value: 'NO', text: 'No' }]"></b-form-select>
          </b-form-group>

          <b-form-group label="Bus de Vuelta:" label-for="invitee-bus-return-select">
            <b-form-select id="invitee-bus-return-select" v-model="currentInvitee.busVuelta" :options="[{ value: 'SI', text: 'Sí' }, { value: 'NO', text: 'No' }]"></b-form-select>
          </b-form-group>

          <b-form-group label="Se Queda a Dormir:" label-for="invitee-sleeping-select">
            <b-form-select id="invitee-sleeping-select" v-model="currentInvitee.dormir" :options="[{ value: 'SI', text: 'Sí' }, { value: 'NO', text: 'No' }]"></b-form-select>
          </b-form-group>

          <b-form-group label="Desayuno:" label-for="invitee-breakfast-select">
            <b-form-select id="invitee-breakfast-select" v-model="currentInvitee.desayuno" :options="[{ value: 'SI', text: 'Sí' }, { value: 'NO', text: 'No' }]"></b-form-select>
          </b-form-group>

          <b-form-group label="Comentarios:" label-for="invitee-comments-input">
            <b-form-textarea id="invitee-comments-input" v-model="currentInvitee.comentari" placeholder="Comentarios" rows="3"></b-form-textarea>
          </b-form-group>
        </div>

        <b-card class="mt-4 p-3 family-section">
          <h5 class="text-center">Campos de Unidad Familiar</h5>
          <b-form-group label="Preguntas Especiales:" label-for="edit-special-questions-input">
            <b-form-select id="edit-special-questions-input" v-model="currentFamilyTranslated.preguntasEspeciales" required :options="['Sí', 'No']" @change="checkFamilyFieldChanges"></b-form-select>
          </b-form-group>
          <b-form-group label="Nota de Costo por Dormir:" label-for="edit-sleep-cost-note-input">
            <b-form-select id="edit-sleep-cost-note-input" v-model="currentFamilyTranslated.notaCostoDormir" required :options="['Sí', 'No']" @change="checkFamilyFieldChanges"></b-form-select>
          </b-form-group>
        </b-card>

        <b-button type="submit" variant="success" class="mt-3">Actualizar</b-button>
        <b-button @click="confirmDeleteInvitee" variant="danger" class="mt-3 ml-2">Eliminar</b-button>
      </b-form>
    </b-modal>

  <b-modal id="confirm-delete-modal" title="Confirmar Eliminación" @ok="deleteInvitee">
    <p>¿Estás seguro de que deseas eliminar este invitado?</p>
  </b-modal>

  <!-- Modal para confirmar actualización de unidad familiar -->
  <b-modal id="confirm-update-family-modal" title="Confirmar Actualización" @ok="performUpdateInvitee">
    <p>Estás a punto de actualizar los siguientes campos a nivel de unidad familiar:</p>
    <ul>
      <li v-for="invitee in currentFamily.invitados" :key="invitee.nombre">{{ invitee.nombre }}</li>
    </ul>
    <p>Cambiarás los siguientes valores:</p>
    <ul>
      <li v-if="currentFamily.preguntasEspeciales !== originalFamily.preguntasEspeciales">
        Preguntas Especiales: {{ originalFamily.preguntasEspeciales ? 'Sí' : 'No' }} -> {{ currentFamily.preguntasEspeciales ? 'Sí' : 'No' }}
      </li>
      <li v-if="currentFamily.notaCostoDormir !== originalFamily.notaCostoDormir">
        Nota de Costo por Dormir: {{ originalFamily.notaCostoDormir ? 'Sí' : 'No' }} -> {{ currentFamily.notaCostoDormir ? 'Sí' : 'No' }}
      </li>
    </ul>
  </b-modal>

  </b-container>
</template>
  
<script>
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { query, ref, get, orderByChild, update, equalTo, remove } from 'firebase/database';
import { auth, database } from '@/config/firebaseConfig.js';
import { getRsvps } from '@/config/firebaseConfig';
import * as XLSX from 'xlsx';
import unidecode from 'unidecode';
import crypto from 'crypto';

export default {
  data() {
    return {
        chartData: {
        labels: ['Confirmados', 'No Confirmados', 'Con Alergias'],
        datasets: [
          {
            label: 'Estadísticas de RSVP',
            data: [this.confirmedGuests, this.unconfirmedGuests, this.invitadosConAlergias],
            backgroundColor: ['#4CAF50', '#FF5252', '#FFC107'],
          },
        ],
      },
      newInvitee: {
        invitados: [{
          nombre: '',
          confirmado: 'FALSE'
        }],
        preguntasEspeciales: 'FALSE',
        notaCostoDormir: 'FALSE'
      },
      currentFamily: {
        preguntasEspeciales: 'FALSE',
        notaCostoDormir: 'FALSE',
        invitados: []
      },
      originalFamily: {
        preguntasEspeciales: 'FALSE',
        notaCostoDormir: 'FALSE',
        invitados: []
      },
      cambios: [],
      addInviteeSuccess: false,
      deleteInviteeSuccess: false,
      updateInviteeSuccess: false,
      familyFieldChanged: false, // Variable para controlar los cambios en los booleanos
      currentInvitee: {},
      originalInviteeName: '',
      inviteeToDelete: null,
      updateButtonText: 'Actualizar Datos',
      isUpdating: false, // Controla la visibilidad del icono animado
      testimonials: [],
      currentPage: 1,
      perPage: 10,
      isLoggedIn: false,
      sortKey: '', // Clave por la que se ordenan los datos
      sortDesc: false, // Dirección de la ordenación
      rsvps: [],
      guests: [],
      searchQuery: '',
      mobileSubmissions: 0,
      pcSubmissions: 0,
      fields: [
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'confirmado', label: 'Confirmado', sortable: true},
        { key: 'alergia', label: 'Alergias', sortable: true },
        { key: 'busLlegada', label: 'Bus Llegada', sortable: true },
        { key: 'busVuelta', label: 'Bus Vuelta', sortable: true },
        { key: 'codigoInvitacion', label: 'Código Invitación', sortable: true },
        { key: 'desayuno', label: 'Desayuno', sortable: true },
        { key: 'dormir', label: 'Dormir', sortable: true },
        { key: 'comentari', label: 'Comentarios', sortable: true },
        { key: 'submittedFromMobile', label: 'Enviado desde Mobil', sortable: true },
        { key: 'timestamp', label: 'Timestamp', sortable: true }
        ],
      email: '', // Agregar email al data para el binding con v-model
      password: '', // Agregar password al data para el binding con v-model
      groupedRsvps: {},
      errorMessage: '', // Mensaje de error para mostrar al usuario
    };
  },
  methods: {
    openEditModal(invitee) {
    const family = this.guests.find(guest => guest.codigoInvitacion === invitee.codigoInvitacion);
    const specificRsvp = this.rsvps.find(rsvp => rsvp.codigoInvitacion === invitee.codigoInvitacion && rsvp.nombre === invitee.nombre);

    this.currentInvitee = {
      ...invitee,
      noRsvp: !specificRsvp,
      alergia: specificRsvp ? specificRsvp.alergia || '' : '',
      busLlegada: specificRsvp ? specificRsvp.busLlegada || '' : '',
      busVuelta: specificRsvp ? specificRsvp.busVuelta || '' : '',
      desayuno: specificRsvp ? specificRsvp.desayuno || '' : '',
      dormir: specificRsvp ? specificRsvp.dormir || '' : '',
      comentari: specificRsvp ? specificRsvp.comentari || '' : ''
    };

    this.originalInviteeName = invitee.nombre;

    if (family) {
      this.currentFamily = {
        ...family,
        invitados: family.invitados || []
      };

      this.originalFamily = JSON.parse(JSON.stringify(family));

      this.currentFamilyTranslated = {
        preguntasEspeciales: family.preguntasEspeciales ? 'Sí' : 'No',
        notaCostoDormir: family.notaCostoDormir ? 'Sí' : 'No'
      };
    }

    this.$bvModal.show('edit-invitee-modal');
  },
  checkFamilyFieldChanges() {
    this.familyFieldChanged = this.currentFamily.preguntasEspeciales !== this.originalFamily.preguntasEspeciales ||
                              this.currentFamily.notaCostoDormir !== this.originalFamily.notaCostoDormir;
  },
  confirmDeleteInvitee() {
      this.$bvModal.show('confirm-delete-modal');
    },
  // Método para eliminar invitado
  async deleteInvitee() {
    try {
      const guestRef = ref(database, `guests/${this.currentInvitee.codigoInvitacion}/invitados/${this.currentInvitee.id}`);
      await remove(guestRef);

      const rsvpQuery = query(ref(database, 'rsvps'), orderByChild('nombre'), equalTo(this.currentInvitee.nombre));
      const rsvpSnapshot = await get(rsvpQuery);
      const deletePromises = [];
      rsvpSnapshot.forEach(childSnapshot => {
        if (childSnapshot.val().codigoInvitacion === this.currentInvitee.codigoInvitacion) {
          deletePromises.push(remove(ref(database, `rsvps/${childSnapshot.key}`)));
        }
      });
      await Promise.all(deletePromises);

      const family = this.guests.find(guest => guest.codigoInvitacion === this.currentInvitee.codigoInvitacion);
      if (family && family.invitados.length === 1) {
        await remove(ref(database, `guests/${this.currentInvitee.codigoInvitacion}`));
      }

      this.fetchGuests();
      this.$bvModal.hide('edit-invitee-modal');
      this.deleteInviteeSuccess = true;

    // Ocultar el mensaje después de 3 segundos
    setTimeout(() => {
      this.deleteInviteeSuccess = false;
    }, 3000);
    } catch (error) {
      console.error('Error al eliminar el invitado:', error);
    }
  },
  showAddInviteeModal() {
      this.$bvModal.show('add-invitee-modal');
      this.resetNewInvitee();
    },
  // Resetear los datos del nuevo invitado
  resetNewInvitee() {
    this.newInvitee = {
      invitados: [{
        nombre: '',
        confirmado: 'Pendiente'
      }],
      preguntasEspeciales: 'No',
      notaCostoDormir: 'No'
    };
  },
  addInviteeField() {
    this.newInvitee.invitados.push({
      nombre: '',
      confirmado: 'Pendiente'
    });
  },

    // Eliminar un campo de invitado
    removeInviteeField(index) {
      if (this.newInvitee.invitados.length > 1) {
        this.newInvitee.invitados.splice(index, 1);
      }
    },
    // Método para añadir un nuevo invitado
    async addInvitee() {
      try {
        const { invitados, preguntasEspeciales, notaCostoDormir } = this.newInvitee;
        const uniqueCode = this.generateUniqueCode(invitados);

        const newInviteeData = {
          invitados: invitados.map(inv => ({
            nombre: inv.nombre,
            confirmado: inv.confirmado === 'Sí' ? true : (inv.confirmado === 'No' ? false : null)
          })),
          preguntasEspeciales: preguntasEspeciales === 'Sí',
          notaCostoDormir: notaCostoDormir === 'Sí'
        };

        await update(ref(database, `guests/${uniqueCode}`), newInviteeData);

        this.resetNewInvitee();
        this.$bvModal.hide('add-invitee-modal');
        this.addInviteeSuccess = true;

        this.fetchGuests();

        setTimeout(() => {
          this.addInviteeSuccess = false;
        }, 3000); // Ocultar la alerta después de 3 segundos
      } catch (error) {
        console.error('Error al añadir el nuevo invitado:', error);
      }
    },

    generateUniqueCode(invitados) {
      const initialLetters = invitados.map(inv => inv.nombre.charAt(0).toUpperCase()).join('');
      const cleanedInitials = unidecode(initialLetters);
      const hash = crypto.createHash('sha256').update(cleanedInitials + Date.now()).digest('hex').slice(0, 3).toUpperCase();
      return `${cleanedInitials}${hash}`;
    },

    async updateInvitee() {
      this.checkFamilyFieldChanges();
      console.log(this.checkFamilyFieldChanges);
      if (this.familyFieldChanged) {
        this.$bvModal.show('confirm-update-family-modal');
      } else {
        await this.performUpdateInvitee();
      }
    },

    async performUpdateInvitee() {
  try {
    const guestRef = ref(database, `guests/${this.currentInvitee.codigoInvitacion}/invitados/${this.currentInvitee.id}`);
    await update(guestRef, {
      nombre: this.currentInvitee.nombre.trim(),
      confirmado: this.currentInvitee.confirmado === 'Sí' ? true : (this.currentInvitee.confirmado === 'No' ? false : null)
    });

    const familyRef = ref(database, `guests/${this.currentInvitee.codigoInvitacion}`);
    await update(familyRef, {
      preguntasEspeciales: this.currentFamilyTranslated.preguntasEspeciales === 'Sí',
      notaCostoDormir: this.currentFamilyTranslated.notaCostoDormir === 'Sí'
    });

    const rsvpQuery = query(ref(database, 'rsvps'), orderByChild('nombre'), equalTo(this.originalInviteeName));
    const rsvpSnapshot = await get(rsvpQuery);
    const updatePromises = [];
    rsvpSnapshot.forEach(childSnapshot => {
      if (childSnapshot.val().codigoInvitacion === this.currentInvitee.codigoInvitacion) {
        const updateData = {
          nombre: this.currentInvitee.nombre.trim(),
          alergia: this.currentInvitee.alergia.trim(),
          busLlegada: this.currentInvitee.busLlegada,
          busVuelta: this.currentInvitee.busVuelta,
          desayuno: this.currentInvitee.desayuno,
          dormir: this.currentInvitee.dormir,
          comentari: this.currentInvitee.comentari.trim(),
        };
        updatePromises.push(update(ref(database, `rsvps/${childSnapshot.key}`), updateData));
      }
    });
    await Promise.all(updatePromises);

    this.fetchGuests();
    this.$bvModal.hide('edit-invitee-modal');
    this.updateInviteeSuccess = true;

    // Ocultar el mensaje después de 3 segundos
    setTimeout(() => {
      this.updateInviteeSuccess = false;
    }, 3000);
  } catch (error) {
    console.error('Error al actualizar el invitado:', error);
  }
}
,

async confirmUpdateFamily() {
  try {
    const familyRef = ref(database, `guests/${this.currentInvitee.codigoInvitacion}`);
    await update(familyRef, {
      preguntasEspeciales: this.currentFamily.preguntasEspeciales === 'Sí',
      notaCostoDormir: this.currentFamily.notaCostoDormir === 'Sí'
    });

    const guestRef = ref(database, `guests/${this.currentInvitee.codigoInvitacion}/invitados/${this.currentInvitee.id}`);
    await update(guestRef, {
      nombre: this.currentInvitee.nombre.trim(),
      confirmado: this.currentInvitee.confirmado === 'Sí' ? true : (this.currentInvitee.confirmado === 'No' ? false : null)
    });

    const rsvpQuery = query(ref(database, 'rsvps'), orderByChild('nombre'), equalTo(this.originalInviteeName));
    const rsvpSnapshot = await get(rsvpQuery);
    const updatePromises = [];
    rsvpSnapshot.forEach(childSnapshot => {
      if (childSnapshot.val().codigoInvitacion === this.currentInvitee.codigoInvitacion) {
        const updateData = {
          nombre: this.currentInvitee.nombre.trim(),
          alergia: this.currentInvitee.alergia.trim(),
          busLlegada: this.currentInvitee.busLlegada,
          busVuelta: this.currentInvitee.busVuelta,
          desayuno: this.currentInvitee.desayuno,
          dormir: this.currentInvitee.dormir,
          comentari: this.currentInvitee.comentari.trim(),
        };
        updatePromises.push(update(ref(database, `rsvps/${childSnapshot.key}`), updateData));
      }
    });
    await Promise.all(updatePromises);

    this.fetchGuests();
    this.$bvModal.hide('edit-invitee-modal');
    this.updateInviteeSuccess = true; // Muestra el mensaje de éxito

    // Ocultar el mensaje después de 3 segundos
    setTimeout(() => {
      this.updateInviteeSuccess = false;
    }, 3000);
  } catch (error) {
    console.error('Error al actualizar la unidad familiar:', error);
  }
},


  resetCurrentInvitee() {
    this.currentInvitee = {};
  },
    applyFilters() {
    if (!this.searchQuery) {
      this.filteredRsvps = this.mergedGuestList;
    } else {
      this.filteredRsvps = this.mergedGuestList.filter(item => {
        return item.nombre.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          item.codigoInvitacion.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    }
    this.currentPage = 1; // Restablece la paginación a la primera página
  },
  onSorted(ctx) {
    this.sortKey = ctx.sortBy;
    this.sortDesc = ctx.sortDesc;
    this.sortData();
  },

  sortData() {
  if (!this.sortKey) return; // No hacer nada si no se ha establecido una clave de ordenación

  // Función de comparación que utiliza sortKey y sortDesc
  const compareFunction = (a, b) => {
    if (a[this.sortKey] < b[this.sortKey]) return this.sortDesc ? 1 : -1;
    if (a[this.sortKey] > b[this.sortKey]) return this.sortDesc ? -1 : 1;
    return 0;
  };

  // Ordenar mergedGuestList directamente
  this.mergedGuestList.sort(compareFunction);
},


    async actualizarEstadoInvitado(codigoInvitacion, idInvitado, estado) {
      try {
        const guestToUpdate = this.guests.find(guest => guest.codigoInvitacion === codigoInvitacion);
        if (!guestToUpdate) throw new Error('Grupo de invitados no encontrado.');

        const invitadoIndex = guestToUpdate.invitados.findIndex(invitado => invitado.id === idInvitado);
        if (invitadoIndex === -1 || idInvitado == null) throw new Error('Invitado no encontrado o pagina aun no cargada.');

        // Ahora que sabemos que el invitado existe, procedemos con la actualización
        const invitadoRef = ref(database, `guests/${codigoInvitacion}/invitados/${idInvitado}`);
        await update(invitadoRef, { confirmado: estado });

        // Actualiza el estado local de manera segura
        this.$set(guestToUpdate.invitados, invitadoIndex, { ...guestToUpdate.invitados[invitadoIndex], confirmado: estado });
      } catch (error) {
        console.error('Error actualizando el estado de confirmación:', error.message);
      }
    },
  // Métodos para cambiar el estado de confirmación. Asegúrate de pasar los argumentos correctos.
    confirmar(codigoInvitacion, idInvitado) {
      this.actualizarEstadoInvitado(codigoInvitacion, idInvitado, true); // true para confirmado
    },
    rechazar(codigoInvitado, idInvitado) {
      this.actualizarEstadoInvitado(codigoInvitado, idInvitado, false); // false para rechazado
    },
    pendiente(codigoInvitado, idInvitado) {
      this.actualizarEstadoInvitado(codigoInvitado, idInvitado, null); // null para pendiente
    },
    async signIn() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.fetchRsvps(); // Asegúrate de llamar a fetchRsvps después de un inicio de sesión exitoso.
        this.errorMessage = '';
        this.email = ''; // Limpiar email
        this.password = ''; // Limpiar contraseña
      } catch (error) {
        console.error("Error en el inicio de sesión: ", error.message);
        this.errorMessage = error.message;
      }
    },
    async signOut() {
      try
      {
        await signOut(auth); // Esta función es parte de Firebase Authentication
        this.isLoggedIn = false;
        // Forzar la actualización de la interfaz de usuario
        this.$forceUpdate(); // O
        // Esperar hasta que Vue haya procesado los cambios y luego ejecutar la siguiente función
        this.$nextTick(() => {
        });
      }
      catch (error) {
        console.error("Error al cerrar sesión: ", error.message);
      }
    },
    async fetchRsvps() {
      try {
        const response = await getRsvps();
        this.rsvps = Object.entries(response).map(([id, rsvp]) => ({
          id, // Incluye el id como parte del objeto
          ...rsvp
        }));

        // Inicializar contadores
        let mobileSubmissions = 0;
        let pcSubmissions = 0;

        // Contar confirmaciones desde móvil y PC
        this.rsvps.forEach(rsvp => {
          if (rsvp.submittedFromMobile) {
            mobileSubmissions++;
          } else {
            pcSubmissions++;
          }
        });

        // Almacenar las estadísticas en el estado del componente
        this.mobileSubmissions = mobileSubmissions;
        this.pcSubmissions = pcSubmissions;
        this.sortData(); // Ordenar después de cargar

      } catch (error) {
        console.error("Error al obtener las confirmaciones: ", error);
      }
    },
    groupRsvps(rsvps) 
    {
      const grouped = {};
      Object.keys(rsvps).forEach(key => {
          const rsvp = rsvps[key];
          const code = rsvp.codigoInvitacion;
          if (!grouped[code]) {
          grouped[code] = { rsvps: [] };
          }
          grouped[code].rsvps.push(rsvp);
      });
      this.groupedRsvps = grouped;
    },
    async exportToExcel() {
      // Verificar si hay datos para exportar
      if (this.rsvps.length === 0) {
          alert('No hay datos para exportar.');
          return;
      }
      try {
          // Convertir los datos a un formato adecuado para XLSX
          const data = this.mergedGuestList.map(rsvp => ({
          Nombre: rsvp.nombre || 'N/A',
          Confirmado: rsvp.confirmado,
          Alergias: rsvp.alergia || 'N/A',
          BusLlegada: rsvp.busLlegada || 'N/A',
          BusVuelta: rsvp.busVuelta || 'N/A',
          CódigoInvitación: rsvp.codigoInvitacion || 'N/A',
          Comentarios: rsvp.comentari || 'N/A',
          submittedFromMobile: rsvp.submittedFromMobile || 'N/A',
          Desayuno: rsvp.desayuno || 'N/A',
          Dormir: rsvp.dormir || 'N/A',
          Timestamp: rsvp.timestamp || 'N/A'
          }));

          // Crear un libro de Excel y una hoja con los datos
          const worksheet = XLSX.utils.json_to_sheet(data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Confirmaciones');

          // Generar un archivo Excel en formato blob
          const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
          const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Crear un enlace temporal para descargar el archivo
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'confirmaciones.xlsx');

          // Simular un clic en el enlace para iniciar la descarga
          document.body.appendChild(link);
          link.click();

          // Limpiar el enlace y liberar el objeto URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
      } catch (error) {
          console.error('Error al exportar a Excel:', error);
          alert('Ocurrió un error al exportar los datos a Excel.');
      }
    },
    async fetchGuests() {
        try {
            const guestsRef = ref(database, 'guests');
            const guestsSnapshot = await get(guestsRef);
            if (guestsSnapshot.exists()) 
            {
              const guestsArray = [];
              guestsSnapshot.forEach((childSnapshot) => {
                  const guest = childSnapshot.val();
                  guest.codigoInvitacion = childSnapshot.key;
                  // Inicializar rsvps como un array vacío para cada invitado
                  guest.rsvps = [];
                  guest.invitados = Object.keys(guest.invitados).map(key => ({
                    ...guest.invitados[key],
                    id: key,
                  }));
                  guestsArray.push(guest);
              });
              this.guests = guestsArray;
              await this.linkRsvpsWithGuests();
            } 
            else 
            {
              console.log('No se encontraron invitados.');
            }
        } catch (error) {
            console.error('Error al obtener los invitados:', error);
        }
    },
    async updateData() {
      try {
        this.updateButtonText = 'Actualizando...';
        this.isUpdating = true;
        await this.fetchRsvps();
        await this.fetchGuests();
        this.isUpdating = false;
        this.updateButtonText = 'Actualizar Datos';
      } catch (error) {
        console.error("Error al actualizar los datos: ", error);
        this.updateButtonText = 'Actualizar Datos';
        this.errorMessage = 'Error al actualizar los datos: ' + error.message;
      }
    },
    async linkRsvpsWithGuests() {
    for (const guestGroup of this.guests) {
        if (guestGroup.codigoInvitacion) {
            const invitadosRef = ref(database, `guests/${guestGroup.codigoInvitacion}/invitados`);
            const snapshot = await get(invitadosRef);
            if (snapshot.exists()) {
                const invitados = snapshot.val();
                // invitados aquí es un objeto cuyas claves son los IDs/índices de cada invitado.
                const invitadosArray = Object.keys(invitados).map(key => ({
                    ...invitados[key],
                    id: key, // Guardar el key o índice como parte del objeto invitado
                }));
                // Actualizar el objeto guestGroup con los invitados vinculados
                guestGroup.invitados = invitadosArray;
            }
        }
    }
},

      // Método para cargar los testimonios de Firebase
      async loadTestimonials() {
        const guestBookRef = ref(database, 'testimonials');
        try {
          const snapshot = await get(guestBookRef);
          if (snapshot.exists()) {
            this.testimonials = [];
            snapshot.forEach(childSnapshot => {
              const testimonial = childSnapshot.val();
              testimonial.id = childSnapshot.key; // Opcional, si necesitas el ID
              const date = new Date(testimonial.date);
              date.toLocaleString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
              });
              this.testimonials.push({
                ...testimonial,
                date: formatDate(date),
              });
            });
          } else {
            console.log("No hay testimonios disponibles.");
          }
        } catch (error) {
          console.error("Error al cargar los testimonios:", error);
        }
    },
    // Método para exportar los testimonios a Excel
    exportTestimonialsToExcel() {
      if (this.testimonials.length === 0) {
        alert('No hay testimonios para exportar.');
        return;
      }

      try {
        const data = this.testimonials.map(({ guestName, message, date }) => ({
          Nombre: guestName,
          Mensaje: message,
          Fecha: date,
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Testimonios');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Testimonios.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error al exportar testimonios a Excel:', error);
        alert('Ocurrió un error al exportar los testimonios a Excel.');
      }
    },
  },

    async created() 
    {
      this.loadTestimonials();
      onAuthStateChanged(auth, async (user) => {
          if (user) 
          {
            this.isLoggedIn = true;
            await this.fetchRsvps();
            await this.fetchGuests();
          } 
          else
          {
            this.isLoggedIn = false;
          }
      });
    },
    computed: {
      currentFamilyTranslated: {
        get() {
          return {
            preguntasEspeciales: this.currentFamily.preguntasEspeciales ? 'Sí' : 'No',
            notaCostoDormir: this.currentFamily.notaCostoDormir ? 'Sí' : 'No'
          };
        },
        set(newValue) {
          this.currentFamily.preguntasEspeciales = newValue.preguntasEspeciales === 'Sí';
          this.currentFamily.notaCostoDormir = newValue.notaCostoDormir === 'Sí';
          this.checkFamilyFieldChanges();
        }
      },
      paginatedData() {
        const start = (this.currentPage - 1) * this.perPage;
        const end = start + this.perPage;
        return this.filteredRsvps.slice(start, end);
      },
      totalRows() {
        return this.filteredRsvps.length;
      },
      invitadosQuedanDormir() {
        return this.mergedGuestList.filter(guest => guest.dormir === 'SI').length;
      },
      invitadosDesayunan() {
        return this.mergedGuestList.filter(guest => guest.desayuno === 'SI').length;
      },
      transporteRequeridoIda() {
        return this.mergedGuestList.filter(guest => guest.busLlegada === 'SI').length;
      },
      transporteRequeridoVuelta() {
        return this.mergedGuestList.filter(guest => guest.busVuelta === 'SI').length;
      },
      invitadosConAlergias() {
        return this.mergedGuestList.filter(guest => {
          const alergia = guest.alergia.trim().toLowerCase(); // Limpia y convierte a minúsculas
          return alergia !== 'n/a' && alergia !== 'no' && alergia !== 'ninguna' && alergia !== 'nada' && alergia !== '' && alergia !== null;
        }).length;
      },
      filteredRsvps() {
        if (!this.searchQuery) {
            return this.mergedGuestList;
        }

        const queryLower = this.searchQuery.toLowerCase();
        return this.mergedGuestList.filter(item => {
        // Convertir a minúsculas para hacer la búsqueda insensible a mayúsculas
        const nombreLower = item.nombre.toLowerCase();
        const codigoLower = item.codigoInvitacion.toLowerCase();

        // Verificar si el nombre o el código de invitación incluye la consulta de búsqueda
        return nombreLower.includes(queryLower) || codigoLower.includes(queryLower);
        });
    },
    mergedGuestList() {
    let mergedList = [];
    this.guests.forEach(guestGroup => {
      guestGroup.invitados.forEach(invitado => {
        const rsvpsForGuest = this.rsvps.filter(rsvp => rsvp.codigoInvitacion === guestGroup.codigoInvitacion);
        const specificRsvp = rsvpsForGuest.find(rsvp => rsvp.nombre === invitado.nombre);

        // Convertir timestamp a Date para ordenar
        let timestampValue = specificRsvp && specificRsvp.timestamp ? convertToDate(specificRsvp.timestamp) : 'N/A';

        mergedList.push({
          codigoInvitacion: guestGroup.codigoInvitacion,
          nombre: invitado.nombre,
          id: invitado.id,
          confirmado: invitado.confirmado === true ? 'Sí' : (invitado.confirmado === false ? 'No' : 'Pendiente'),
          preguntasEspeciales: guestGroup.preguntasEspeciales ? 'Sí' : 'No',
          alergia: specificRsvp ? specificRsvp.alergia || 'N/A' : 'N/A',
          busLlegada: specificRsvp ? specificRsvp.busLlegada || 'N/A' : 'N/A',
          busVuelta: specificRsvp ? specificRsvp.busVuelta || 'N/A' : 'N/A',
          comentari: specificRsvp ? specificRsvp.comentari || 'N/A' : 'N/A',
          submittedFromMobile: specificRsvp ? (specificRsvp.submittedFromMobile ? 'Sí' : 'No') : 'N/A',
          desayuno: specificRsvp ? specificRsvp.desayuno || 'N/A' : 'N/A',
          dormir: specificRsvp ? specificRsvp.dormir || 'N/A' : 'N/A',
          timestamp: timestampValue,
        });
      });
    });

      // Ordenar por timestamp, manteniendo 'N/A' al final
      mergedList.sort((a, b) => {
        if (a.timestamp === 'N/A') return 1;
        if (b.timestamp === 'N/A') return -1;
        return b.timestamp - a.timestamp;
      });
      
    // Convertir fechas de vuelta a cadenas para mostrar
      mergedList = mergedList.map(item => ({
        ...item,
        timestamp: item.timestamp !== 'N/A' ? formatDate(item.timestamp) : 'N/A',
      }));

    return mergedList;
  },

    // Ajusta las propiedades confirmedGuests y unconfirmedGuests para usar mergedGuestList
    confirmedGuests() {
        return this.mergedGuestList.filter(guest => guest.confirmado === 'Sí').length;
    },
    unconfirmedGuests() {
        return this.mergedGuestList.filter(guest => guest.confirmado === 'No').length;
    },
      pendingGuests() {
        return this.mergedGuestList.filter(guest => guest.confirmado === 'Pendiente').length;
    },
  },
  watch: {
    currentFamilyTranslated: {
      handler() {
        this.checkFamilyFieldChanges();
      },
      deep: true
    },
    // Observa los cambios en searchQuery para actualizar la paginación correctamente
    searchQuery() {
      this.currentPage = 1; // Restablece a la primera página
    },
    // Puedes observar rsvps si cambian dinámicamente para ajustar la paginación
    rsvps: {
      handler() {
      },
      deep: true, // Observa cambios profundos dentro de rsvps
    },
  },
  };

  function convertToDate(dateTimeStr) {
        let [date, time] = dateTimeStr.split(' ');
        let [day, month, year] = date.split('/').map(num => parseInt(num, 10));
        let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));

        // Los meses en JavaScript van de 0 a 11, por lo que restamos 1 al mes.
        return new Date(year, month - 1, day, hours, minutes);
    }
    function formatDate(date) {
        if (!(date instanceof Date) || isNaN(date)) return 'N/A';
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Enero es 0
        let year = date.getFullYear();
        let hours = date.getHours().toString().padStart(2, '0');
        let minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  </script>
  
  <style>
    .custom-card {
        background-color: #f8f9fa;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .small-text {
      font-size: 0.8rem !important; /* Ajusta el tamaño del texto según sea necesario */
    }
    .text-center {
        text-align: center;
    }

    .b-card {
    transition: transform 0.3s ease;
    }

    .b-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    }
    .custom-collapse {
      transition: all .3s ease;
      overflow: hidden;
    }
    @media (max-width: 768px) {
    /* Estilos específicos para pantallas de tamaño tablet y móvil */
    .custom-table {
    font-size: 0.9rem; /* Tamaño de fuente reducido para dispositivos móviles */
    }
  }

  @media (max-width: 768px) {
    /* Estilos específicos para pantallas de tamaño tablet y móvil */
    .custom-table {
        font-size: 0.9rem; /* Tamaño de fuente reducido para dispositivos móviles */
    }
    /* Asegura margen dentro de la b-card */
    .d-flex.align-items-center.mx-2.my-1 {
        margin: 10px !important; /* Aumenta el margen para mejorar el espaciado */
    }
}

@media (max-width: 576px) {
    /* Estilos específicos para pantallas de móviles */
    .custom-table {
        font-size: 0.7rem; /* Tamaño de fuente aún más reducido para dispositivos muy pequeños */
    }
    /* Ajusta el margen para dispositivos muy pequeños */
    .d-flex.align-items-center.mx-2.my-1 {
        margin: 8px !important; /* Ajusta según sea necesario para tu diseño */
    }
}

@media (min-width: 769px) {
    /* Estilos específicos para pantallas grandes */
    .custom-table {
        font-size: 1rem;
    }
    /* Puedes ajustar los márgenes para pantallas grandes si es necesario */
}

.bg-light-blue {
  background-color: #A7C7E7; /* Un azul sereno que evoca calma sin perder el enfoque */
  color: white;
}

.bg-light-green {
  background-color: #A9D4B2; /* Un verde pastel, relajante y acogedor */
  color: white;
}

.bg-purple {
  background-color: #9B72AA; /* Un púrpura suave, que mantiene la nobleza del púrpura con una sensación más ligera */
  color: white;
}
.bg-primary-soft {
  background-color: #B0C4DE; /* Un azul acero claro, menos saturado */
  color: white;
}
.bg-success-soft {
  background-color: #AED581; /* Un verde claro y refrescante */
  color: white;
}
.bg-danger-soft {
  background-color: #E57373; /* Un rojo suave que sigue comunicando precaución pero de manera menos alarmante */
  color: white;
}
.bg-warning-soft {
  background-color: #FFD54F; /* Un amarillo ámbar suave */
  color: black; /* Un texto más oscuro para garantizar el contraste */
}
.bg-info-soft {
  background-color: #4FC3F7; /* Un cian claro, suave y acogedor */
  color: white;
}
.editable-name {
  cursor: pointer;
  color: #007bff; /* Color de enlace Bootstrap por defecto */
}
.editable-name:hover {
  text-decoration: underline;
}
.family-section {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  </style>
  