<template>
  <div>
    <b-navbar toggleable="lg" variant="faded" class="bg-custom mb-2 mt-5">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="main-navbar mx-auto">
        <b-nav-item :to="{ name: 'Home' }">¡Nos casamos!</b-nav-item>
        <b-nav-item :to="{ name: 'Boda' }">La boda</b-nav-item>
        <b-nav-item :to="{ name: 'Rsvp' }">¡Confirma tu asistencia!</b-nav-item>
        <b-nav-item :to="{ name: 'Location' }">¿Cómo llegar?</b-nav-item>
        <b-nav-item :to="{ name: 'Regal' }">Regalo</b-nav-item>
        <b-nav-item :to="{ name: 'Photos' }">Fotos</b-nav-item>
        <b-nav-item :to="{ name: 'Testimonials' }">Libro de visitas</b-nav-item>
        <b-nav-item :to="{ name: 'Information' }">Información Útil</b-nav-item>
        <b-nav-item :to="{ name: 'Faqs' }">FAQs</b-nav-item>
      </b-navbar-nav>
      </b-collapse>

    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style>
@media only screen and (min-width:1200px) {
  .main-navbar {
    width: 90%;
    display: flex;
    justify-content: space-around; 
    font-size: 2.2rem; 
  }
  .nav-item {
    white-space: nowrap;
    font-size: 2.0rem; 
  }
}

.router-link-exact-active {
  border: 1px solid #E6DECA;
  border-radius: 3px;
  background: #A8AFA3 !important;
  color: #4E4E4E !important;
}

.nav-item {
  font-family: 'Oranienbaum';
  width: fit-content;

}

/* Styles for smaller devices like samsung galaxy A51/A71 */
@media only screen and (max-width: 299px) {
  .nav-item {
    font-size: 0.83rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
    font-weight: 600;
  }
}

/* Styles for small resolution mobiles */
@media only screen and (min-width: 300px) and (max-width: 350px) {
  .nav-item {
    font-size: 0.9rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
    font-weight: 600;
  }
}

/* Styles for samsung galaxy S8+ */
@media only screen and (min-width: 351px) and (max-width: 370px) {
  .nav-item {
    font-size: 0.95rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
    font-weight: 600;
  }
}

/* Styles for large mobiles */
@media only screen and (min-width: 371px) and (max-width: 449px) {
  .nav-item {
    font-size: 0.95rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
    font-weight: 600;
  }
}

/* Styles for super large mobile */
@media only screen and (min-width: 450px) and (max-width: 767px) {
  .nav-item {
    font-size: 1.0rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
    font-weight: 600;
  }
}

/* Styles for tablets and small laptops */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-item {
    font-size: 1.6rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
    font-weight: 600;
  }
}

/* Styles for large laptops and desktops (HD resolution) */
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .nav-item {
    font-size: 1.8rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
    font-weight: 600;
  }
}

/* Styles for in between HD and FullHD resolution */
@media only screen and (min-width: 1500px) and (max-width: 1899px) {
  .nav-item {
    font-size: 1.9rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
  }
}

/* Styles for Full HD screens */
@media only screen and (min-width: 1900px) and (max-width: 2400px) {
  .nav-item {
    font-size: 2.0rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
  }
}

/* Additional styles for Quad HD */
@media only screen and (min-width: 2400px) and (max-width: 3000px){
  .nav-item {
    font-size: 2.2rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
  }
}

/* Additional styles for extreme high-resolution screens (4K and beyond) */
@media only screen and (min-width: 3001px) {
  .nav-item {
    font-size: 3.6rem; /* Tamaño de fuente ajustado para tabletas y pequeñas pantallas de escritorio */
  }
}
</style>
