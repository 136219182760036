<template>
  <b-container fluid="lg" class="my-5" style="max-width: 1300px;">
    <b-row class="align-stretch">
      <b-col xs="12" class="mb-3">
        <b-card class="custom-card mb-3">
          <b-card-body>
            <b-card-title>
              Información Útil
            </b-card-title>
            <b-card-text>
              Hemos creado esta sección para ofrecer información práctica y recomendaciones tanto para aquellos que {{ $t('viajais') }} desde fuera de España y {{ $t('deseais') }} disfrutar al máximo de Barcelona, como a quienes {{ $t('os6') }} interese explorar Sallent y la comarca del Bages.
            </b-card-text>
            <b-card-text class="mb-1">
              ¡Estamos deseando celebrar con {{ $t('vosotros') }}! 🎉❤️
            </b-card-text>
          </b-card-body>

        <b-accordion class="scrollable-column" id="main-info-accordion">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.barcelona-info variant="primary" class="parent-accordion d-flex align-items-center justify-content-center">
                <b-icon icon="plus"></b-icon> Barcelona
              </b-button>
            </b-card-header>
            <b-collapse id="barcelona-info" accordion="main-info-accordion" role="tabpanel">
              <b-card-text class="mr-2 mt-2">
                Muchos de {{ $t('vosotros') }} {{ $t('venis') }} desde fuera de España para acompañarnos y {{ $t('os6') }} queremos ofrecer esta "mini guía práctica" 🌍✈️         
              </b-card-text>
              <b-card-text class="mr-2">
              La ciudad condal {{ $t('os') }} conquistará con su ambiente vibrante, su arquitectura única y su deliciosa gastronomía. {{ $t('preparaos') }} para pasear por calles históricas, {{ $t('perderos') }} en sus animados barrios y saborear platos típicos en encantadores restaurantes 🚶‍♂️🍷
              </b-card-text>
              <b-card-text class="mr-2">
                Queremos que {{ $t('os3') }} como en casa, así que no {{ $t('dudeis') }} en preguntarnos cualquier cosa.
              </b-card-text>
              <b-card-body> 
                <!-- Alojamiento -->
                <b-card no-body class="custom-card mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.info-util-alojamiento variant="info" class="child-accordion" ref="alojamientoButton">Alojamiento en Barcelona</b-button>
                    </b-card-header>
                    <b-collapse id="info-util-alojamiento" accordion="info-util-accordion" role="tabpanel" @shown="scrollToElement('alojamientoButton')">
                      <b-card-text class="mr-2 mt-2">
                        En Barcelona {{ $t('podreis') }} encontrar innumerables hoteles y apartamentos (airbnb), {{ $t('os6') }} detallamos algunos aspectos que {{ $t('os') }} podrán ayudar a elegir la ubicación.
                      </b-card-text>
                    <b-card-body>
                        <!-- Tabla de alojamiento -->
                        <div class="table-responsive">
                            <b-table striped hover :items="alojamientoItems" small class="mb-3 custom-table">
                            <template #cell(Barrio)="data">
                                <b>{{ data.item.Barrio }}</b>
                            </template>
                            <template #cell(A_favor)="data">
                                <div class="text-success">{{ data.item.A_favor }}</div>
                            </template>
                            <template #cell(En_contra)="data">
                                <div class="text-danger">{{ data.item.En_contra }}</div>
                            </template>
                            <template #cell(Estaciones_de_Metro)="data">
                                <div class="text-info">{{ data.item.Estaciones_de_Metro }}</div>
                            </template>
                            </b-table>
                        </div>
                    </b-card-body>
                    </b-collapse>
                </b-card>
  
              <!-- Transporte -->
              <b-card no-body class="custom-card mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.info-util-transporte variant="info" class="child-accordion" ref="transporteButton">Transporte en Barcelona</b-button>
                </b-card-header>
                <b-collapse id="info-util-transporte" accordion="info-util-accordion" role="tabpanel" @shown="scrollToElement('transporteButton')">
                  <b-card-body>
                      <!-- Tabla de transporte -->
                      <div class="table-responsive">
                          <b-table striped hover :items="transporteItems" small class="mb-3 custom-table">
                          <template #cell(Modo)="data">
                              <b>{{ data.item.Modo }}</b>
                          </template>
                          <template #cell(Descripción)="data">
                              <div>{{ data.item.Descripción }}</div>
                          </template>
                          <template #cell(Recomendaciones)="data">
                              <div class="text-info">{{ data.item.Recomendaciones }}</div>
                          </template>
                          </b-table>
                      </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
  
              <!-- Otros -->
              <b-card no-body class="custom-card mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.info-util-otros variant="info" class="child-accordion" ref="consejosButton">Otros Consejos e Información</b-button>
                </b-card-header>
                <b-collapse id="info-util-otros" accordion="info-util-accordion" role="tabpanel" @shown="scrollToElement('consejosButton')">
                  <b-card-body>
                      <!-- Lista de otros consejos e información -->
                      <div class="table-responsive">
                          <b-table striped hover :items="otrosItems" small class="mb-3 custom-table">
                          <template #cell(Categoría)="data">
                              <b>{{ data.item.Categoría }}</b>
                          </template>
                          <template #cell(Descripción)="data">
                              <div>{{ data.item.Descripción }}</div>
                          </template>
                          <template #cell(Recomendaciones)="data">
                              <div class="text-info">{{ data.item.Recomendaciones }}</div>
                          </template>
                          </b-table>
                      </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Información Práctica sobre Barcelona -->
              <b-card no-body class="custom-card mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.info-util-practica variant="info" class="child-accordion" ref="informacionButton">Información Práctica sobre Barcelona</b-button>
                </b-card-header>
                <b-collapse id="info-util-practica" accordion="info-util-accordion" role="tabpanel" @shown="scrollToElement('informacionButton')">
                  <b-card-body>
                      <!-- Lista de información práctica -->
                      <div class="table-responsive">
                          <b-table striped hover :items="practicaItems" small class="mb-3 custom-table">
                          <template #cell(Tema)="data">
                              <b>{{ data.item.Tema }}</b>
                          </template>
                          <template #cell(Descripción)="data">
                              <div>{{ data.item.Descripción }}</div>
                          </template>
                          </b-table>
                      </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-card-body>
            </b-collapse>
            <!-- Sallent Info -->
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.sallent-info variant="primary" class="parent-accordion d-flex align-items-center justify-content-center">
                <b-icon icon="plus"></b-icon> Sallent
              </b-button>
            </b-card-header>
            <b-collapse id="sallent-info" accordion="main-info-accordion" role="tabpanel">
              <b-card-text class="mr-2 mt-2">
                Para todos aquellos que {{ $t('osapetezca') }}  {{ $t('quedaros') }} a dormir cerca a Sellarès, conocer el encanto de Sallent y sus calles medievales y disfrutar de lugares cercanos y atractivos dentro de la comarca del Bages, {{ $t('os6') }} dejamos esta "mini guía". 
              </b-card-text>
              <b-card-body>
                <!-- Alojamiento en Sallent y alrededores -->
                <b-card no-body class="custom-card mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.alojamiento-sallent variant="info" class="child-accordion" ref="alojamientoSallentButton">Alojamiento en Sallent y alrededores</b-button>
                  </b-card-header>
                  <b-collapse id="alojamiento-sallent" accordion="info-util-accordion-sallent" role="tabpanel" @shown="scrollToElement('alojamientoSallentButton')">
                    <b-card-body>
                      <div class="table-responsive">
                        <b-table striped hover :items="montserratItems" small class="mb-3 custom-table">
                          <template #cell(Ubicación)="data">
                            <b>{{ data.item.Ubicación }}</b>
                          </template>
                          <template #cell(Detalles)="data">
                            <div>{{ data.item.Detalles }}</div>
                          </template>
                        </b-table>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <!-- Atractivos y Lugares de interés en Sallent -->
                <b-card no-body class="custom-card mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.atractivos-sallent variant="info" class="child-accordion" ref="atractivosSallentButton">Atractivos y Lugares de interés</b-button>
                  </b-card-header>
                  <b-collapse id="atractivos-sallent" accordion="info-util-accordion-sallent" role="tabpanel" @shown="scrollToElement('atractivosSallentButton')">
                    <b-card-body>
                      <div class="table-responsive">
                        <b-table striped hover :items="manresaItems" small class="mb-3 custom-table">
                          <template #cell(Ubicación)="data">
                            <b>{{ data.item.Ubicación }}</b>
                          </template>
                          <template #cell(Detalles)="data">
                            <div>{{ data.item.Detalles }}</div>
                          </template>
                        </b-table>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-card-body>
            </b-collapse>
        </b-accordion>
        </b-card>  
      </b-col>
    </b-row>
          <b-modal id="orientation-modal" v-model="showOrientationSuggestion" title="Sugerencia de Visualización" centered hide-footer hide-header-close hide-header>
        <div div class="d-flex justify-content-center align-items-center">
          <b-icon icon="aspect-ratio" class="mr-2"></b-icon>
          Para una mejor experiencia, por favor, cambia tu dispositivo a orientación horizontal.
        </div>
      </b-modal>
    </b-container>
  </template>
  
  <script>
export default {
  name: "SallentBarcelonaGuide",
  data() {
    return {
      showOrientationSuggestion: false,
      currentDisplay: null,
      descriptionText: "",
      montserratItems: [
        {
          Ubicación: "B&B Sallent",
          Detalles: "Ubicado a 10 minutos en coche desde Sellarès Rural y en el mismo pueblo de Sallent."
        },
        {
          Ubicación: "Mas de la Sala, Sallent",
          Detalles: "Hotel ubicado a 15 minutos en coche desde Sellarès Rural."
        },
        {
          Ubicación: "Hotel Món Sant Benet",
          Detalles: "Ubicado en Sant Fruitós de Bages, a 20 minutos en coche desde Sellarès Rural."
        },
        {
          Ubicación: "Manresa",
          Detalles: "Ciudad a 25 minutos en coche desde Sellarès Rural. Manresa cuenta con mucha oferta de hoteles y airbnb."
        }
      ],
      manresaItems: [
        {
          Ubicación: "Montserrat",
          Detalles: "Conocida por su impresionante belleza natural, con formaciones rocosas únicas y vistas panorámicas espectaculares. En lo alto de la montaña se encuentra el monasterio benedictino de Santa María de Montserrat, un importante lugar de peregrinación y espiritualidad. Además, cuenta con rutas de senderismo para todos los niveles que permiten explorar la naturaleza y disfrutar de vistas impresionantes."
        },
        {
          Ubicación: "Manresa",
          Detalles: "Cuenta con un rico patrimonio arquitectónico, incluyendo la Basílica de Santa María de la Seu, una impresionante iglesia gótica situada en el corazón de la ciudad. Además, es conocida por su deliciosa gastronomía, que incluye platos tradicionales catalanes y una amplia variedad de restaurantes que ofrecen cocina de alta calidad."
        },
        {
          Ubicación: "Sant Benet de Bages",
          Detalles: "Antiguo monasterio benedictino restaurado y convertido en un centro cultural y turístico, donde los visitantes pueden explorar la historia y la arquitectura del lugar. Cuenta con hermosos jardines para pasear y disfrutar de la tranquilidad y la belleza del entorno."
        },
        {
          Ubicación: "Cardona",
          Detalles: "Tiene atractivos turísticos tales como el Parque Natural de la Montaña de Sal de Cardona que destaca por su impresionante geología, con formaciones de sal gema que crean un paisaje subterráneo espectacular; así como el Castillo de Cardona, una fortaleza medieval del siglo XI, ofrece vistas panorámicas y una rica historia que incluye batallas, leyendas y una arquitectura imponente."
        },
        {
          Ubicación: "Mura",
          Detalles: "Un pueblo medieval en la comarca del Bages, con calles empedradas, casas de piedra y una iglesia románica. Tiene un castillo que es una fortaleza del siglo XII con unas vistas impresionantes. ¡Ideal para desconectar y disfrutar de la naturaleza y tranquilidad!"
        }
      ],
      alojamientoItems: [
        {
            Barrio: "Barceloneta",
            A_favor: "Playa y ambiente marítimo. Cuenta con amplia oferta de cafés y restaurantes",
            En_contra: "Puede ser ruidoso y con muchos turistas. Además, precios turísticos en restaurantes y bares",
            Estaciones_de_Metro: "Barceloneta"
        },
        {
            Barrio: "Eixample Esquerra",
            A_favor: "Barrio con amplia oferta de cafés, restaurantes y hoteles. Muy bien comunicado con transporte público y famoso por su arquitectura modernista",
            En_contra: "Puede ser un poco más caro",
            Estaciones_de_Metro: "Hospital Clínic, Entença"
        },
        {
            Barrio: "Eixample Dreta",
            A_favor: "Zona con edificios señoriales y calles comerciales elegantes. Muy bien comunicado con transporte público. Aquí se encuentran algunas de las principales obras de Gaudí (Casa Batlló, Casa Milà…)",
            En_contra: "Puede ser un poco más caro y, dependiendo de la zona, puede ser muy turístico y ruidoso.",
            Estaciones_de_Metro: "Diagonal, Passeig de Gràcia"
        },
        {
            Barrio: "El Raval / Barrio Gótico / El Born",
            A_favor: "Estos barrios están dentro del Corazón histórico de la ciudad (Catedral, Barrio Gótico, Ramblas, Mercado de la Boquería). Son barrios de calles estrechas y edificios medievales; multiculturales; y, con ambiente artístico y alternativo. Cuenta con amplia oferta hotelera y de restaurantes.",
            En_contra: "Puede ser ruidoso y abarrotado de turistas. Puede ser peligroso por la noche y es importante andar con cuidado (pueden robarte la cartera o el móvil). Precios turísticos en todos sitios.",
            Estaciones_de_Metro: "Liceu, Jaume I, Plaça Catalunya"
        },
        {
            Barrio: "Gràcia",
            A_favor: "Barrio bohemio con ambiente artístico, muy transitable y con amplia oferta de restaurantes y bares",
            En_contra: "Puede ser un poco difícil llegar en transporte público (no es el mejor barrio conectado). Suele ser algo ruidoso.",
            Estaciones_de_Metro: "Fontana"
        },
        {
            Barrio: "Sants",
            A_favor: "Barrio popular con mezcla de zonas residenciales y comerciales. Cuenta con amplia oferta de restaurantes y comidas. Está muy bien comunicado con transporte público (además tiene la estación principal de Barcelona - Estación Sants)",
            En_contra: "No tiene tantos monumentos históricos. Dependiendo de la zona, puede ser algo ruidoso.",
            Estaciones_de_Metro: "Sants Estació"
        },
        {
            Barrio: "Poblenou",
            A_favor: "Barrio emergente con atmósfera moderna y creativa. Está muy cerca de la playa y es ideal para amantes del arte urbano y la vida nocturna.",
            En_contra: "No está tan cerca del centro de la ciudad y no está tan bien comunicado en transporte público",
            Estaciones_de_Metro: "Poblenou"
        },
        {
            Barrio: "Sagrada Familia",
            A_favor: "Barrio pequeño, aquí se encuentra la Sagrada Familia. Se puede encontrar opciones de alojamiento de precio asequible.",
            En_contra: "No tiene tanta oferta comercial. No está tan cerca del centro de la ciudad.",
            Estaciones_de_Metro: "Sagrada Família"
        },
        {
            Barrio: "Sant Antoni",
            A_favor: "Barrio tranquilo con mercado tradicional y tiendas vintage. Bien comunicado con el centro de la ciudad. Se pueden encontrar alojamientos más económicos que en Eixample, por ejemplo",
            En_contra: "Opciones de alojamiento limitada.",
            Estaciones_de_Metro: "Sant Antoni"
        },
        {
            Barrio: "Sant Gervasi-Galvany",
            A_favor: "Barrio residencial ideal para quienes buscan tranquilidad y exclusividad",
            En_contra: "No está tan cerca del centro de la ciudad. Es uno de los barrios más caros.",
            Estaciones_de_Metro: "Muntaner, Sarrià"
        }],
        transporteItems: [
          {
            Modo: "Alquiler de Coches",
            Descripción: "Alquilar un coche te dará flexibilidad para moverte por Barcelona y sus alrededores. Hay varias agencias de alquiler tanto en el aeropuerto como en la ciudad.",
            Recomendaciones: "Compara precios online y considera reservar con antelación. No olvides verificar si necesitas un permiso de conducir internacional."
          },
          {
            Modo: "Transporte Público",
            Descripción: "Barcelona ofrece una amplia red de transporte público para moverte con facilidad: tren, metro, tranvía y buses.",
            Recomendaciones: "Con billetes integrados como la T-Casual o la Hola Barcelona Travel Card, podrás usar todos estos medios de transporte, puedes ingresar a la web de TMB o descargarte la app para planificar tus viajes. Además, recomendamos mucho utilizar Google Maps para ver las conexiones y horario de transporte público."
          },
          {
            Modo: "Taxi y Servicios de VTC",
            Descripción: "Los taxis y servicios como Uber o Cabify ofrecen una opción cómoda y directa para moverse por Barcelona, especialmente de noche.",
            Recomendaciones: "Los taxis son fáciles de encontrar. Para Uber o Cabify, asegúrate de tener la app instalada en tu móvil."
          },
          {
            Modo: "Paseo a Pie",
            Descripción: "Barcelona es una ciudad con barrios que invitan a ser explorados a pie. Pasear por las Ramblas, el Barrio Gótico, y Paseo de Gracia ofrece una visión única de la cultura local.",
            Recomendaciones: "Lleva calzado cómodo y prepárate para descubrir rincones encantadores y arquitectura impresionante. Recomendamos utilizar Google Maps."
          },
          {
            Modo: "Autobuses Turísticos",
            Descripción: "Los autobuses turísticos son una excelente manera de ver los principales sitios de interés de Barcelona sin preocuparte por planificar rutas.",
            Recomendaciones: "Compra billetes de 1 o 2 días para subir y bajar ilimitadamente. Perfecto para una primera visión general de la ciudad."
          },
          {
            Modo: "Scooters Eléctricos",
            Descripción: "Alquilar scooters eléctricos es una forma divertida y ágil de moverse por Barcelona, permitiéndote acceder a lugares que otros transportes no pueden.",
            Recomendaciones: "Usa apps como Lime o Bird para encontrar y alquilar scooters cercanos. Siempre sigue las normas de tráfico y usa casco."
          },
          {
            Modo: "Donkey Republic",
            Descripción: "Donkey Republic es un servicio global de alquiler de bicicletas que permite alquilar bicicletas de manera flexible a través de una aplicación móvil. Este sistema ofrece alquileres por horas o días, adecuado para poder explorar la ciudad a tu propio ritmo.",
            Recomendaciones: "Descarga la aplicación de Donkey Republic para acceder fácilmente a las bicicletas disponibles en toda la ciudad. Considera elegir el plan de alquiler que mejor se ajuste a la duración de tu estancia, ya sea por unas pocas horas o varios días, para disfrutar de un medio de transporte economico y una experiencia más local ."
          }
        ],
        otrosItems: [
          {
            Categoría: "Restaurantes Recomendados",
            Descripción: "Barcelona ofrece una amplia gama de opciones gastronómicas, desde tapas tradicionales hasta alta cocina. Estos son algunos que recomendamos: Can Culleretes, el restaurante más antiguo de Barcelona, conocido por su cocina catalana clásica; Cal Boter, para los amantes de la carne a la brasa; El Xampanyet, perfecto para una experiencia de tapas auténticas; Antigua, con un toque moderno en platos tradicionales; y 7 Portes, famoso por sus paellas.",
            Recomendaciones: "Haz reservas con antelación, especialmente para restaurantes populares. Te aconsejamos que no dejes de probar platos locales como el pa amb tomàquet, la crema catalana, la butifarra amb seques, la paella, la escudella o los canelones a la catalana."
          },
          {
            Categoría: "Lugares de Interés",
            Descripción: "¡Sumérgete en la cultura! No puedes dejar Barcelona sin recorrer las obras de Gaudí: la Sagrada Familia (obra maestra de Gaudí), la Pedrera, la Casa Batlló, el Parc Güell y el Palau Güell. Tampoco te puedes ir sin explorar el Barrio Gótico, disfrutar del Parc de la Ciutadella, pasear por Las Ramblas y probar la gastronomía local en el Mercat de La Boquería, disfrutar de un día de playa (Barceloneta, Bogatell o Mar Bella, son de las playas más populares) o de un día de montaña (Collserola) para conseguir las mejores vistas a la ciudad.",
            Recomendaciones: "Compra entradas online para evitar largas colas. Considera visitar algunos sitios muy temprano por la mañana o al terminar el día para evitar las multitudes. Te sugerimos visitar museos y centros culturales, como el Museu Nacional d'Art de Catalunya (MNAC) o el Centro de Cultura Contemporánea de Barcelona (CCCB), que suelen organizar exposiciones especiales durante este mes."
          },
          {
            Categoría: "Consejos Prácticos para Turistas",
            Descripción: "Barcelona es una ciudad segura, pero como en cualquier gran ciudad, mantén tus pertenencias seguras y sé consciente de tu entorno, especialmente en áreas turísticas concurridas y en transportes públicos.",
            Recomendaciones: "Lleva siempre contigo tu pasaporte y una tarjeta de crédito o débito. Utiliza el WiFi gratuito disponible en muchos cafés y plazas públicas para evitar cargos por roaming."
          },
          {
            Categoría: "Excursiones de Un Día",
            Descripción: "Si tienes tiempo, considera hacer excursiones de un día a lugares como Montserrat, Sitges, o Girona para explorar más de Cataluña.",
            Recomendaciones: "Los trenes y autobuses ofrecen conexiones convenientes a muchos de estos destinos. Verifica horarios y compra tus billetes con antelación."
          }
        ],
        practicaItems: 
        [
          {
            Tema: "Pagos",
            Descripción: "Se recomienda llevar algo de efectivo para pequeñas compras, aunque la mayoría de comercios aceptan tarjetas de crédito y débito."
          },
          {
            Tema: "Electricidad",
            Descripción: "El voltaje estándar es de 230 V con una frecuencia de 50 Hz. Los enchufes son del tipo europeo, con dos clavijas redondas."
          },
          {
            Tema: "Conectividad",
            Descripción: "Barcelona ofrece buena cobertura de red móvil y Wi-Fi. Muchos bares, restaurantes y plazas públicas ofrecen Wi-Fi gratuito."
          },
          {
            Tema: "Agua Potable",
            Descripción: "El agua de grifo es totalmente segura para beber en Barcelona, aunque algunos prefieren el sabor del agua embotellada."
          },
          {
            Tema: "Número de Emergencias",
            Descripción: "El número de emergencia en España es el 112. Puedes llamarlo gratuitamente desde cualquier teléfono para solicitar asistencia policial, médica o de bomberos."
          },
          {
            Tema: "Horarios de Comidas",
            Descripción: "Las comidas en España suelen ser más tardías que en otros países. La comida (almuerzo) se sirve entre las 2 pm y las 4 pm, mientras que la cena se toma a partir de las 9 pm."
          },
          {
            Tema: "Propina",
            Descripción: "En Barcelona, las propinas no son obligatorias. Pero, si se ha disfrutado la comida y el servicio y te apetece dejar un pequeño extra, ¡siempre será bien recibido!"
          }
        ],
      };
    },
    methods: {
    toggleDisplay(location) {
      this.currentDisplay = location;
      if (location === 'Barcelona') {
        this.descriptionText = "Descubre Barcelona: Una ciudad vibrante llena de cultura, arquitectura y gastronomía.";
      } else if (location === 'Sallent') {
        this.descriptionText = "Explora Sallent y sus alrededores: Un lugar encantador con rica historia y naturaleza impresionante.";
      }
    },
    checkOrientationAndShowSuggestion() {
      // Verifica primero si ya se ha mostrado el modal antes en la sesión actual
      if (!this.showOrientationSuggestion && !sessionStorage.getItem('orientationModalShown')) {
        if (window.innerWidth < window.innerHeight) {
          this.showOrientationSuggestion = true;
          // Marca que el modal ya se ha mostrado en la sesión actual
          sessionStorage.setItem('orientationModalShown', 'true');
          
          setTimeout(() => {
            this.showOrientationSuggestion = false;
          }, 5000);
        }
      }
    },
    scrollToElement(refName) {
      this.$nextTick(() => {
        this.$refs[refName].scrollIntoView({ behavior: 'smooth' });
        this.checkOrientationAndShowSuggestion();
      });
    },
  }
};
  </script>

  <style scoped>
  /* Cambiar el fondo de todos los b-accordion */
.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.92);
}
/* Estilo para acordeones padres */
.b-button.parent-accordion {
  background-color: #0056b3 !important;
  color: white !important;
  margin-bottom: 10px; /* Espacio entre acordeones padres e hijos */
}

/* Estilo para acordeones hijos */
.b-button.child-accordion {
  background-color: #6c757d !important; /* Gris para hijos */
  color: white !important;
  margin-left: 20px; /* Indentación para indicar jerarquía */
  margin-bottom: 5px;
}

/* Resaltar acordeones padres con sombra */
.custom-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}

/* Espaciado y estilo para cabeceras de acordeón */
.b-card-header {
  padding: 0.5rem 1.25rem; /* Ajuste de padding */
  background-color: #f8f9fa; /* Fondo claro para resaltar */
  border-bottom: 1px solid #e3e6ea; /* Borde sutil */
}
.custom-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Efecto hover para profundidad adicional */
}

.scrollable-column {
  max-height: 90vh; /* Ajusta esto según tus necesidades */
  overflow-y: auto; /* Habilita desplazamiento vertical si es necesario */
  scrollbar-width: none; /* Para Firefox */
  -ms-overflow-style: none; /* Para Internet Explorer 10+ */
}
.scrollable-column::-webkit-scrollbar {
  display: none; /* Para Safari y Chrome */
}

.custom-card .card-title, .custom-card .card-text {
  color: #495057; /* Mantener coherencia con el diseño */
  margin-bottom: 1rem;
}

card-subtitle {
  color: #3F4939;
  font-family: 'CaudexBold';
  font-size: 1.0em;
}

.custom-card .card-text {
  font-family: 'Avenir';
  font-size: 1.1rem; /* Ajuste para legibilidad */
  color: #495057; /* Facilitar la lectura */
}

.text-success {
    color: #5cb85c; /* Verde más suave */
}

.orientation-suggestion {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,0.7);
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000; /* Asegúrate de que sea visible sobre otros elementos */
}


.text-danger {
    color: #d9534f; /* Rojo más suave */
}

  .text-info {
    color: #495057 !important;
  }

  @media (max-width: 768px) {
    /* Estilos específicos para pantallas de tamaño tablet y móvil */
    .custom-table {
    font-size: 0.9rem; /* Tamaño de fuente reducido para dispositivos móviles */
    }
  }

  @media (max-width: 576px) {
    /* Estilos específicos para pantallas de móviles */
    .custom-table {
    font-size: 0.7rem; /* Tamaño de fuente reducido para dispositivos móviles */
    }
  }

  @media (min-width: 769px) {
    /* Estilos específicos para pantallas de móviles */
    .custom-table {
    font-size: 1rem; 
    }
  }
</style>
